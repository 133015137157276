import React from 'react'

function SortButton(props) {
    return (
        <div className={`sort-btn-group ${props.SortButtonClasses ? props.SortButtonClasses : ""}`}
            onClick={(e) => props.onSortClicked && props.onSortClicked(e)}>
            <button className={`c-btn sort-btn-transparent sort-btn-active`} type="button"><span class={props.sorted ? 'fa fa-arrow-up-wide-short fa-lg' : 'fa fa-arrow-down-wide-short fa-lg'}></span></button>
            <button className={`c-btn sort-btn-transparent`} type="button">Sort</button>
        </div>
    )
}

export default SortButton;