import React, { useEffect, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';

// import './css/pageNavigation.css';

function PageNavigationLoadMore(props) {
    let pageNumbersGroups = [];
    let pageNumbers = [];

    const [selectedPage, setSelectedPage] = useState(props.selectedPage ? props.selectedPage : 0);
    const [currentGroup, setCurrentGroup] = useState(0);

    useEffect(() => {
        setSelectedPage(props.selectedPage);
        if (props.selectedPage === 0) {
            setCurrentGroup(0);
        }
    }, [props.selectedPage]);

    let pageNumberCount = 0;
    for (let i = 0; i < props.totalPages; i++) {
        pageNumberCount++;
        pageNumbers.push(
            // <span key={nanoid()} className={`page-number ${selectedPage === i ? "page-number-selected" : ""}`} onClick={() => props.onPageClicked(i)}> {i + 1}</span >
            <li key={nanoid()}><a href="#" className={`${selectedPage === i ? "active" : ""}`} onClick={() => props.onPageClicked(i)}>{i + 1}</a></li>
        );
        if (pageNumberCount === props.totalVisiblePageNumbers || i === props.totalPages - 1) {
            pageNumbersGroups.push(
                <React.Fragment>
                    {pageNumbers}
                </React.Fragment>
            );
            pageNumbers = [];
            pageNumberCount = 0;
        }
    }



    const onPreviousGroupClicked = () => {
        let prevPage = selectedPage - 1;
        if (prevPage < 0) {
            return;
        }
        else if (prevPage !== 0 && (prevPage + 1) % props.totalVisiblePageNumbers === 0) {
            setCurrentGroup(currentGroup - 1);
        }

        // if (previousGroup < 0) {
        //     return;
        // }
        //props.onPageClicked(previousGroup * props.totalVisiblePageNumbers);
        props.onPageClicked(prevPage);
    }

    const onNextGroupClicked = () => {
        let nextPage = selectedPage + 1;
        if (nextPage > props.totalPages - 1) {
            return;
        }
        else if (nextPage % props.totalVisiblePageNumbers === 0) {
            let nextGroup = currentGroup + 1;
            setCurrentGroup(nextGroup);
        }

        // if (nextGroup > pageNumbersGroups.length - 1) {
        //     return;
        // }
        //props.onPageClicked(nextGroup * props.totalVisiblePageNumbers);
        props.onPageClicked(nextPage);
    }

    let loadMoreCount = (selectedPage + 2 === props.totalPages) ? props.totalPageItems - ((selectedPage + 1) * props.itemsPerPage) : props.itemsPerPage;

    return (
        <>
            {
                (selectedPage + 1) < props.totalPages &&
                <div className="d-flex justify-content-center p-3" >
                    <button type="button" className={`c-btn c-btn-gray`} onClick={() => onNextGroupClicked()}><i class="fa-solid fa-sync me-2" />Load {loadMoreCount && loadMoreCount} More</button>
                </div>
            }
        </>
    );




}

export default PageNavigationLoadMore;