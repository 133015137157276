import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectLoginStatus, updateLoginStatus } from '../reducerSlices/accountsSlice';
import { isFormValid } from '../../../component/Controls/services/formService';
import { accountService } from "../services/accountService";
import { fetchStatus } from "../../../api/client";
import EmailText from '../../../component/Controls/textboxes/emailText';
import PasswordText from '../../../component/Controls/textboxes/passwordText';
import companyLogoImg from '../../../assets/images/logo/companyLogo.png';
import loginBackgroundImg from '../../../assets/images/mix/login_background.png';

function Login() {

  const dispatch = useDispatch();
  const history = useHistory();

  const LOGIN_FORM = "LoginForm";

  const currentYear = new Date().getFullYear();
  const loginStatus = useSelector(selectLoginStatus);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lfSubmitted, setLFSubmitted] = useState(false);

  const onLoginClicked = (e) => {
    e.preventDefault();
    if (isFormValid(LOGIN_FORM)) {
      accountService.login({ email: email, password: password, authenticationType: "TOKEN" });
    }
    else {
      setLFSubmitted(true);
    }
  }

  useEffect(() => {
    if (loginStatus === fetchStatus.SUCCEEDED) {
      if (accountService.isAuthenticated()) {
        dispatch(updateLoginStatus());
        //const { returnUrl } = props.location.state || { returnUrl: { pathname: "/" } };
        if (accountService.isAdmin() || accountService.isClientAdmin() || accountService.isClientUser()) {
          if (accountService.isAdmin()) {
            history.push("/jobOrder");
          }
          else {
            history.push("/");
          }
        }
        else {
          history.push("/");
        }
      }
    }
  }, [loginStatus, dispatch, history]);
  useEffect(() => {
    if (loginStatus === fetchStatus.FAILED) {

    }
  }, [loginStatus]);

  return (
    <>
    <div class="container-fluid px-0">
                <div class="row no-gutter">
                    <div class="col-md-6 d-none d-md-inline bg-image">
                        <div class="login d-flex py-2">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-11 col-xl-11 mx-auto">
                                        <div className="text-center mb-5">
                                            <h1>Client Portal</h1>
                                            <p>Welcome to the client portal</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                        <img src={loginBackgroundImg} className="w-75" alt="background"   />
                                        </div>
                                    </div>
                                </div>
                                <footer class="footer pt-5 text-end">
                                    {/* <div className="pt-md-3"> */}
                                    <a href="https://twitter.com/"
                                        target="_balcnk"
                                        // onMouseEnter={() => setTwitterImage(twitterImg2)}
                                        // onMouseLeave={() => setTwitterImage(twitterImg)}
                                    >
                                        <i class="fa-brands fa-twitter text-white"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/"
                                        target="_balcnk"
                                        // onMouseEnter={() => setLinkedinImage(linkedinImg2)}
                                        // onMouseLeave={() => setLinkedinImage(linkedinImg)}
                                        className="ms-3"
                                    >
                                        {/* <img src={linkedinImage} alt="no" /> */}
                                        <i class="fa-brands fa-linkedin text-white"></i>
                                    </a>
                                    <a href="https://www.facebook.com/"
                                        target="_balcnk"
                                        // onMouseEnter={() => setFacebookImage(facebookImg2)}
                                        // onMouseLeave={() => setFacebookImage(facebookImg)}
                                        className="ms-3"
                                    >
                                        {/* <img src={facebookImage} alt="no" /> */}
                                        <i class="fa-brands fa-facebook-f text-white"></i>
                                    </a>
                                    {/* </div> */}
                                </footer>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 bg-light">
                        <div class="login d-flex py-2">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-10 col-xl-7 mx-auto">
                                        <img src={companyLogoImg} alt="company logo" className="mx-auto d-block w-50" />
                                        <p class="login-text my-4">Login</p>
                                        <p class="text-muted mb-4">Enter your credentials to access your account.</p>
                                        <label className="field-label">Email</label>
                                        <EmailText
                                            id="txtEmail"
                                            name="txtEmail"
                                            EmailTextClasses="form-control p-2"
                                            placeholder="Email"
                                            required={true}
                                            errorMessage="Please enter email"
                                            form={LOGIN_FORM}
                                            submited={lfSubmitted}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)} />
                                        <label className="field-label mt-4">Password</label>
                                        <PasswordText
                                            id="txtPassword"
                                            name="txtPassword"
                                            PasswordTextClasses="form-control p-2"
                                            placeholder="Password"
                                            required={true}
                                            errorMessage="Please enter password"
                                            form={LOGIN_FORM}
                                            submited={lfSubmitted}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                        <button type="submit" className="col-md-12 btn btn-login pt-2 mt-4" onClick={(e) => onLoginClicked(e)} >Login</button>
                                        <div class="custom-control custom-checkbox mb-3 mt-2">
                                            <label for="" class="custom-control-label">Forgot Password?</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-lg-10 col-xl-7 mx-auto text-center">
                                        {/* Need access ?  <span className="text-pink"><Link to="/register" className="text-pink">Register</Link></span> */}
                                    </div>
                                </div>
                                <footer class="footer mt-5 py-5 text-center">
                                    <span class="text-muted">Copyright © {currentYear} - Client Portal | All Rights Reserved.</span>
                                </footer>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
    </>

    // <div class="login-page bg-light">
    //   <div class="container">
    //     <div class="row">
    //       <div class="col-lg-10 offset-lg-1">
    //         <h3 class="mb-3">Client Portal</h3>
    //         <div class="bg-white shadow rounded">
    //           <div class="row">
    //             <div class="col-md-7 pe-0">
    //               <div class="form-left h-100 py-5 px-5">
    //                 <form action="" class="row g-4">
    //                   <div class="col-12">
    //                     <label>Username<span class="text-danger">*</span></label>
    //                     {/* <div class="input-group">
    //                       <div class="input-group-text"><i class="fa fa-user"></i></div>
    //                       <input type="text" class="form-control" placeholder="Enter Username" />
    //                     </div> */}
    //                     <EmailText
    //                       id="txtEmail"
    //                       name="txtEmail"
    //                       EmailTextClasses="form-control p-2"
    //                       placeholder="Email"
    //                       required={true}
    //                       errorMessage="Please enter email"
    //                       form={LOGIN_FORM}
    //                       submited={lfSubmitted}
    //                       value={email}
    //                       onChange={(e) => setEmail(e.target.value)} />
    //                   </div>

    //                   <div class="col-12">
    //                     <label>Password<span class="text-danger">*</span></label>
    //                     {/* <div class="input-group">
    //                       <div class="input-group-text"><i class="fa fa-key"></i></div>
    //                       <input type="text" class="form-control" placeholder="Enter Password" />
    //                     </div> */}
    //                     <PasswordText
    //                       id="txtPassword"
    //                       name="txtPassword"
    //                       PasswordTextClasses="form-control p-2"
    //                       placeholder="Password"
    //                       required={true}
    //                       errorMessage="Please enter password"
    //                       form={LOGIN_FORM}
    //                       submited={lfSubmitted}
    //                       value={password}
    //                       onChange={(e) => setPassword(e.target.value)} />
    //                   </div>

    //                   <div class="col-sm-6">
    //                   </div>

    //                   <div class="col-sm-6">
    //                     <a href="/" class="float-end text-primary">Forgot Password?</a>
    //                   </div>

    //                   <div class="col-12">
    //                     <button type="submit" class="btn btn-primary px-4 float-end mt-4" onClick={(e) => onLoginClicked(e)}>login</button>
    //                   </div>
    //                 </form>
    //               </div>
    //             </div>
    //             <div class="col-md-5 ps-0 d-none d-md-block">
    //               <div class="form-right h-100 bg-primary text-white text-center pt-5">
    //                 <i class="bi bi-bootstrap"></i>
    //                 <h2 class="fs-1">Welcome Back!!!</h2>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Login