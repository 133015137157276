import { createSlice } from "@reduxjs/toolkit";

const initialState ={
    title:"",
    show:false
}

export const jobRequestModalPopUpSlice = createSlice({
    name:'jobRequestModalPopUp',
    initialState,
    reducers:{
        showJobRequestModal: (state, action) => {
            const {title} = action.payload;
            state.title = title;
            state.show =true;
        },
        closeJobRequestModal: state =>{
            state.title = "";
            state.show = false;
        }
    }
});

export const { showJobRequestModal, closeJobRequestModal} = jobRequestModalPopUpSlice.actions;

export default jobRequestModalPopUpSlice.reducer;

export const selectJobRequestModalPopUpDetail = state => {
    return {
        title: state.jobRequestModalPopUp.title,
        show: state.jobRequestModalPopUp.show
    }
}