import React, { useState } from 'react';
import DashboardCard from './component/dashboardCard';
import TopNavBar from '../../../component/TopNavBar/TopNavBar';
import { useDispatch } from 'react-redux';
import { showJobRequestModal } from '../../../component/Controls/modals/reducerSlices/jobRequestModalPopUpSlice';
import VerticalBarChart from '../../../component/Controls/chart/verticalBarChart';
import PieChart from '../../../component/Controls/chart/pieChart';

import job1Img from '../../../assets/images/clipart/job-1.svg';
import job2Img from '../../../assets/images/clipart/job-2.svg';
import job3Img from '../../../assets/images/clipart/job-3.svg';
import people1Img from '../../../assets/images/clipart/people-1.svg';
import people2Img from '../../../assets/images/clipart/people-2.svg';
import people3Img from '../../../assets/images/clipart/people-3.svg';
import InputGroup from '../../../component/Controls/custom/inputGroup';
import ComboBarLine from '../../../component/Controls/chart/comboBarLine';

// import womanSketchImg from './../../../assets/images/clipart/woman-sketch.svg';
// import boyFaceImg from './../../../assets/images/clipart/boy-face.svg';

// import i9Img from '../../../assets/images/clipart/i9.svg';
// import fileImg from './../../../assets/images/clipart/file.svg';
// import genderImg from './../../../assets/images/clipart/gender.svg';
// import termsImg from './../../../assets/images/clipart/terms.svg';
// import folderImg from './../../../assets/images/clipart/folder.svg';
// import pdfImg from '../../../assets/images/clipart/pdf.svg';


function Dashboard() {

  const dispatch = useDispatch();

  const onClickJobRequest = () => {
    dispatch(showJobRequestModal({
      title: "Add New Job"
    }))
  }

  // const TableData = <>
  //   <tr>
  //     <td>Thelma Dean</td>
  //     <td>(123)456-0000</td>
  //     <td>MO-1</td>
  //     <td>Machine Operator</td>
  //     <td>Buena Park</td>
  //     <td>Closed steel Toe Shoes, Jeans, T-Shirt</td>
  //     <td>1st</td>
  //   </tr>
  //   <tr>
  //     <td>Victoria  Cordova</td>
  //     <td>(123)456-0000</td>
  //     <td>Gl-1</td>
  //     <td>General Labor</td>
  //     <td>Bell</td>
  //     <td>jeans, closed shoes</td>
  //     <td>2nd</td>
  //   </tr>
  //   <tr>
  //     <td>Jeremiah Saunders</td>
  //     <td>(123)456-0000</td>
  //     <td>GLO</td>
  //     <td>General Office</td>
  //     <td>Buena Park</td>
  //     <td>Dress Casual</td>
  //     <td>3rd</td>
  //   </tr>
  // </>;

  // const JobTableData = <>
  //   <tr>
  //     <td>Jr Machine Operator</td>
  //     <td>5/7/2023</td>
  //     <td>5/17/2023</td>
  //     <td>Buena Park</td>
  //     <td>1st</td>
  //     <td>10</td>
  //   </tr>
  //   <tr>
  //     <td>Sr. Machine Operator</td>
  //     <td>5/8/2023</td>
  //     <td>5/18/2023</td>
  //     <td>Bell</td>
  //     <td>2nd</td>
  //     <td>7</td>
  //   </tr>
  //   <tr>
  //     <td>Int Machine Operator</td>
  //     <td>5/9/2023</td>
  //     <td>5/20/2023</td>
  //     <td>Buena Park</td>
  //     <td>3rd</td>
  //     <td>26</td>
  //   </tr>
  //   <tr>
  //     <td>Int Machine Operator</td>
  //     <td>5/9/2023</td>
  //     <td>5/20/2023</td>
  //     <td>Buena Park</td>
  //     <td>3rd</td>
  //     <td>26</td>
  //   </tr>
  //   <tr>
  //     <td>Int Machine Operator</td>
  //     <td>5/9/2023</td>
  //     <td>5/20/2023</td>
  //     <td>Buena Park</td>
  //     <td>3rd</td>
  //     <td>26</td>
  //   </tr>
  //   <tr>
  //     <td>Int Machine Operator</td>
  //     <td>5/9/2023</td>
  //     <td>5/20/2023</td>
  //     <td>Buena Park</td>
  //     <td>3rd</td>
  //     <td>26</td>
  //   </tr>
  //   <tr>
  //     <td>Int Machine Operator</td>
  //     <td>5/9/2023</td>
  //     <td>5/20/2023</td>
  //     <td>Buena Park</td>
  //     <td>3rd</td>
  //     <td>26</td>
  //   </tr>
  //   <tr>
  //     <td>Int Machine Operator</td>
  //     <td>5/9/2023</td>
  //     <td>5/20/2023</td>
  //     <td>Buena Park</td>
  //     <td>3rd</td>
  //     <td>26</td>
  //   </tr>
  // </>;

  const peopleData = [
    {
      EmployeeName: "John Doe",
      MobileNo: "123-456-7890",
      JobTitle: "Junior",
      Position: "General Labor",
      Location: "Compton",
      DressCode: "Casual",
      Shift: "Morning",
      Status: "New"
    },
    {
      EmployeeName: "Jane Smith",
      MobileNo: "987-654-3210",
      JobTitle: "Senior",
      Position: "Machine Operator",
      Location: "Bell",
      DressCode: "Formal",
      Shift: "Night",
      Status: "Repeat"
    },
    {
      EmployeeName: "Michael Johnson",
      MobileNo: "555-555-5555",
      JobTitle: "Junior",
      Position: "Accounting",
      Location: "Bell",
      DressCode: "Business Casual",
      Shift: "Day",
      Status: "New"
    },
    {
      EmployeeName: "Emily Davis",
      MobileNo: "999-999-9999",
      JobTitle: "Senior",
      Position: "General Labor",
      Location: "Compton",
      DressCode: "Casual",
      Shift: "Night",
      Status: "Repeat"
    },
    {
      EmployeeName: "David Brown",
      MobileNo: "777-777-7777",
      JobTitle: "Junior",
      Position: "Machine Operator",
      Location: "Bell",
      DressCode: "Casual",
      Shift: "Morning",
      Status: "New"
    },
    {
      EmployeeName: "Sarah Wilson",
      MobileNo: "444-444-4444",
      JobTitle: "Senior",
      Position: "Accounting",
      Location: "Bell",
      DressCode: "Business Casual",
      Shift: "Day",
      Status: "Repeat"
    },
    {
      EmployeeName: "Robert Thompson",
      MobileNo: "471-117-1111",
      JobTitle: "Junior",
      Position: "General Labor",
      Location: "Compton",
      DressCode: "Casual",
      Shift: "Night",
      Status: "New"
    },
    {
      EmployeeName: "Jessica Martinez",
      MobileNo: "888-888-8888",
      JobTitle: "Senior",
      Position: "Machine Operator",
      Location: "Bell",
      DressCode: "Casual",
      Shift: "Morning",
      Status: "Repeat"
    },
  ];

  const jobData = [
    {
      "JobTitle": "Jr Machine Operator",
      "StartDate": "2023-07-10",
      "EndDate": "2023-07-31",
      "Location": "Compton",
      "Shift": "Day",
      "NoApplicant": 20,
      "Fill": 15,
      "Open": 5
    },
    {
      "JobTitle": "Sr. Machine Operator",
      "StartDate": "2023-07-15",
      "EndDate": "2023-08-15",
      "Location": "Bell",
      "Shift": "Night",
      "NoApplicant": 15,
      "Fill": 12,
      "Open": 3
    },
    {
      "JobTitle": "Jr General Labor",
      "StartDate": "2023-07-20",
      "EndDate": "2023-08-10",
      "Location": "Compton",
      "Shift": "Day",
      "NoApplicant": 10,
      "Fill": 10,
      "Open": 0
    },
    {
      "JobTitle": "Sr General Labor",
      "StartDate": "2023-07-25",
      "EndDate": "2023-08-25",
      "Location": "Bell",
      "Shift": "Night",
      "NoApplicant": 10,
      "Fill": 8,
      "Open": 2
    },
    {
      "JobTitle": "Jr Machine Operator",
      "StartDate": "2023-08-01",
      "EndDate": "2023-08-31",
      "Location": "Compton",
      "Shift": "Night",
      "NoApplicant": 5,
      "Fill": 4,
      "Open": 1
    },
    {
      "JobTitle": "Sr. Machine Operator",
      "StartDate": "2023-08-05",
      "EndDate": "2023-09-05",
      "Location": "Bell",
      "Shift": "Day",
      "NoApplicant": 12,
      "Fill": 10,
      "Open": 2
    },
    {
      "JobTitle": "Jr General Labor",
      "StartDate": "2023-08-10",
      "EndDate": "2023-09-10",
      "Location": "Compton",
      "Shift": "Night",
      "NoApplicant": 10,
      "Fill": 8,
      "Open": 2
    },
    {
      "JobTitle": "Sr General Labor",
      "StartDate": "2023-08-15",
      "EndDate": "2023-09-15",
      "Location": "Bell",
      "Shift": "Day",
      "NoApplicant": 20,
      "Fill": 15,
      "Open": 5
    },
    {
      "JobTitle": "Jr Machine Operator",
      "StartDate": "2023-08-20",
      "EndDate": "2023-09-20",
      "Location": "Compton",
      "Shift": "Day",
      "NoApplicant": 10,
      "Fill": 8,
      "Open": 2
    },
    {
      "JobTitle": "Sr. Machine Operator",
      "StartDate": "2023-08-25",
      "EndDate": "2023-09-25",
      "Location": "Bell",
      "Shift": "Night",
      "NoApplicant": 20,
      "Fill": 15,
      "Open": 5
    }
  ];

  const [peopleQuitModalShow, setPeopleQuitModalShow] = useState(false);
  const [modalPopUpTitle, setModalPopUpTitle] = useState("");

  const onClickPeopleQuit = (e, people) => {
    setPeopleQuitModalShow(true);
    setModalPopUpTitle(people);
  }

  const onClickPeopleQuitModalClose = (e) => {
    setPeopleQuitModalShow(false);
  }


  return (

    <>

      <TopNavBar />



      <div className='row mt-4 overflow-hidden px-md-3'>
        <div className='col-md-6 col-lg-3'>
          <DashboardCard
            title="Total Job"
            number={120}
            faIcon="fa-briefcase"
            iconBackgroundClass="bg-secondary" />
        </div>
        <div className='col-md-6 col-lg-3'>
          <DashboardCard
            title="Job Request"
            number={7}
            faIcon="fa-briefcase"
            iconBackgroundClass="bg-primary" />
        </div>

        <div className='col-md-6 col-lg-3'>
          <DashboardCard
            title="Job In Progress"
            number={10}
            faIcon="fa-briefcase"
            iconBackgroundClass="bg-warning" />
        </div>

        <div className='col-md-6 col-lg-3'>
          <DashboardCard
            title="Active Hire"
            number={250}
            faIcon="fa-user-check"
            iconBackgroundClass="bg-danger" />
        </div>
      </div>

      {/* <div className='row my-3 px-md-3'>
        <div className='col-12 col-lg-4 mb-4'>
          <div className='chart-container'>
            <p className='chat-title '><i class="fa-solid fa-person-walking-dashed-line-arrow-right me-2"></i>Job Candidate</p>
            <ComboBarLine
              labelsChart={["Jr Machine Operator","Sr. Machine Operator","Jr General Labor","Sr General Labor"]}
              barChartValue={[25,15,35,20]}
              lineChartValue={[20,15,20,10]}
              barChartDatasetsLable={'Total'}
              lineChartDatasetsLable={'Fill'}
            />
          </div>
        </div>
      </div> */}

      <div className='row my-3 px-md-3'>
        <div className='col-12 col-lg-4 mb-4'>
          <div className='chart-container'>
            <p className='chat-title '><i class="fa-solid fa-person-walking-dashed-line-arrow-right me-2"></i>New Joiner</p>
            <VerticalBarChart
              legendChart="New Joiner"
              labelsChart={['1-July-2023', '2-July-2023', '3-July-2023', '4-July-2023', '5-July-2023', '6-July-2023', '7-July-2023']}
              valuesChart={[5, 15, 10, 27, 20, 7, 23]}
            />
          </div>
        </div>

        <div className='col-12 col-lg-4 mb-4'>
          <div className='chart-container'>
            <p className='chat-title '><i class="fa-solid fa-user-tie me-2"></i>Show Up</p>
            <VerticalBarChart
              legendChart="Show Up"
              labelsChart={['1-July-2023', '2-July-2023', '3-July-2023', '4-July-2023', '5-July-2023', '6-July-2023', '7-July-2023']}
              valuesChart={[4, 10, 8, 26, 15, 3, 15]}
            />
          </div>
        </div>

        <div className='col-12 col-lg-2 mb-4'>
          <div className='chart-container'>
            <p className='chat-title'><i class="fa-solid fa-briefcase me-2"></i>Job Type</p>
            <PieChart
              legendChart="Job Yype"
              labelsChart={['Light', 'Heavy']}
              valuesChart={[185, 72]}
            />
          </div>
        </div>

        <div className='col-12 col-lg-2 mb-4'>
          <div className='chart-container'>
            <p className='chat-title'><i class="fa-solid fa-person-walking-arrow-loop-left me-2"></i>New / Repeat</p>
            <PieChart
              legendChart="Gender"
              labelsChart={['New', 'Repeat']}
              valuesChart={[7, 28]}
              backgroundColor={['rgba(252, 223, 92, 0.3)', 'rgba(54, 162, 235, 0.2)']}
              borderColor={['rgb(252, 223, 92)', 'rgb(54, 162, 235)']}
            />
          </div>
        </div>
      </div>

      <div className='row my-3 px-md-3'>
        <div className='col-md-12 col-lg-6'>
          <div className="dashboard-container">
            <div className="dashboard-header" style={{ backgroundImage: `url(${job1Img})` }}>
              <h3 className="dashboard-header-title">Request Job</h3>
              <span className="dashboard-header-sub-title">10</span>
              <span className={`dashboard-card-sub-number ${10 > 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                <i className={`fa ${10 > 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> 18%
              </span>
              <span>vs. pervious month</span>
            </div>
            <div className="dashboard-body">
              <div className='table-container'>
                <div className='d-flex justify-content-between'>
                  <p className='table-title'>
                    {/* <i className='fa  fa-briefcase me-2' /> */}
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search" />
                  </p>
                  <div className='float-end'>
                    <button className='btn btn-outline-secondary' onClick={onClickJobRequest}>
                      <i class="fa-solid fa-xl  fa-person-circle-plus" ></i>
                    </button>
                  </div>
                </div>
                <div class="table-responsive mt-2 dashboard-table">
                  <table class="table table-bordered table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th>Job Title</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Location</th>
                        <th>Shift</th>
                        <th>No Applicant</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        jobData.map((d) => {
                          return <tr>
                            <td>{d.JobTitle}</td>
                            <td>{d.StartDate}</td>
                            <td>{d.EndDate}</td>
                            <td>{d.Location}</td>
                            <td>{d.Shift}</td>
                            <td>{d.NoApplicant}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-12 col-lg-6'>
          <div className="dashboard-container">
            <div className="dashboard-header" style={{ backgroundImage: `url(${people1Img})` }}>
              <h3 className="dashboard-header-title">Total Upcoming People</h3>
              <span className="dashboard-header-sub-title">15</span>
            </div>
            <div className="dashboard-body">
              <div className='table-container'>
                <div className='d-flex justify-content-between'>
                  <p className='table-title'>
                    {/* <i className='fa fa-user-clock me-2' /> */}
                    {/* Upcoming People */}
                    <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Search" />

                  </p>
                  <div className='float-end'>
                    
                  </div>
                </div>
                <div class="table-responsive mt-2 dashboard-table">
                  <table class="table table-bordered table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th>Employee Name</th>
                        <th>Mobile No</th>
                        <th>Job Tiitle</th>
                        <th>Position</th>
                        <th>Location</th>
                        {/* <th>Dress Code</th> */}
                        <th>Shift</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        peopleData.map((d) => {
                          return <tr>
                            <td>{d.EmployeeName}</td>
                            <td>{d.MobileNo}</td>
                            <td>{d.JobTitle}</td>
                            <td>{d.Position}</td>
                            <td>{d.Location}</td>
                            {/* <td>{d.DressCode}</td> */}
                            <td>{d.Shift}</td>
                            <td>
                              <span class={`badge rounded-pill ${d.Status === 'New' ? 'bg-primary' : 'bg-warning'}`}>{d.Status}</span>
                            </td>
                            <td>
                              <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                                <option selected>--Select--</option>
                                <option value="1">Showed Up</option>
                                <option value="2">Not Showed Up</option>
                                <option value="3">Rejected</option>
                              </select>
                            </td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row my-3 px-md-3'>
        <div className='col-md-12 col-lg-6 mt-3'>
          <div className="dashboard-container">
            <div className="dashboard-header" style={{ backgroundImage: `url(${job2Img})` }}>
              <h3 className="dashboard-header-title">Current Job</h3>
              <span className="dashboard-header-sub-title">7</span>
              <span className={`dashboard-card-sub-number ${10 > 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                <i className={`fa ${10 > 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> 15%
              </span>
              <span>vs. pervious month</span>
            </div>
            <div className="dashboard-body">
              <div className='table-container'>
                {/* <div className='d-flex justify-content-between'>
                  <p className='table-title'>
                    <i className='fa  fa-briefcase me-2' />
                    Current Job
                  </p>
                  <div className='float-end'>
                    
                  </div>
                </div> */}
                <div class="table-responsive mt-2 dashboard-table">
                  <table class="table table-bordered table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th>Job Title</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Location</th>
                        <th>Shift</th>
                        <th>No Applicant</th>
                        <th>Fill</th>
                        <th>Open</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        jobData.map((d) => {
                          return <tr>
                            <td>{d.JobTitle}</td>
                            <td>{d.StartDate}</td>
                            <td>{d.EndDate}</td>
                            <td>{d.Location}</td>
                            <td>{d.Shift}</td>
                            <td>{d.NoApplicant}</td>
                            <td>{d.Fill}</td>
                            <td>{d.Open}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-12 col-lg-6 mt-3'>
          <div className="dashboard-container">
            <div className="dashboard-header" style={{ backgroundImage: `url(${people2Img})` }}>
              <h3 className="dashboard-header-title">Total Active People</h3>
              <span className="dashboard-header-sub-title">58</span>
            </div>
            <div className="dashboard-body">
              <div className='table-container'>
                {/* <div className='d-flex justify-content-between'>
                  <p className='table-title'>
                    <i className='fa fa-user-check me-2' />
                    Active People
                  </p>
                  <div className='float-end'>
                    
                  </div>
                </div> */}
                <div class="table-responsive mt-2 dashboard-table">
                  <table class="table table-bordered table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th>Employee Name</th>
                        <th>Mobile No</th>
                        <th>Job Tiitle</th>
                        <th>Position</th>
                        <th>Location</th>
                        <th>Shift</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        peopleData.map((d) => {
                          return <tr>
                            <td>{d.EmployeeName}</td>
                            <td>{d.MobileNo}</td>
                            <td>{d.JobTitle}</td>
                            <td>{d.Position}</td>
                            <td>{d.Location}</td>
                            <td>{d.Shift}</td>
                            <td>
                              <button type="button" class="btn btn-danger btn-sm" onClick={(e) => onClickPeopleQuit(e, d.EmployeeName)}  >Quit <i class="fa-solid fa-person-walking-dashed-line-arrow-right ms-2"></i></button>
                            </td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='row my-3 px-md-3'>
        <div className='col-md-12 col-lg-6 mt-3'>
          <div className="dashboard-container">
            <div className="dashboard-header" style={{ backgroundImage: `url(${job3Img})` }}>
              <h3 className="dashboard-header-title">Total Completed Job</h3>
              <span className="dashboard-header-sub-title">35</span>
              <span className={`dashboard-card-sub-number ${10 > 0 ? 'dashboard-card-sub-number-success' : 'dashboard-card-sub-number-danger'} me-3`}>
                <i className={`fa ${10 > 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down"}`}></i> 72%
              </span>
              <span>vs. pervious month</span>
            </div>
            <div className="dashboard-body">
              <div className='table-container'>
                {/* <div className='d-flex justify-content-between'>
                  <p className='table-title'>
                    <i className='fa  fa-briefcase me-2' />
                    Completed Job
                  </p>
                  <div className='float-end'>
                    
                  </div>
                </div> */}
                <div class="table-responsive mt-2 dashboard-table">
                  <table class="table table-bordered table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th>Job Title</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Location</th>
                        <th>Shift</th>
                        <th>No Applicant</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        jobData.map((d) => {
                          return <tr>
                            <td>{d.JobTitle}</td>
                            <td>{d.StartDate}</td>
                            <td>{d.EndDate}</td>
                            <td>{d.Location}</td>
                            <td>{d.Shift}</td>
                            <td>{d.NoApplicant}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-12 col-lg-6 mt-3'>
          <div className="dashboard-container">
            <div className="dashboard-header" style={{ backgroundImage: `url(${people3Img})` }}>
              <h3 className="dashboard-header-title">Total Previous Worked People</h3>
              <span className="dashboard-header-sub-title">100</span>
            </div>
            <div className="dashboard-body">
              <div className='table-container'>
                {/* <div className='d-flex justify-content-between'>
                  <p className='table-title'>
                    <i className='fa fa-user-users me-2' />
                    Old People
                  </p>
                  <div className='float-end'>
                    
                  </div>
                </div> */}
                <div class="table-responsive mt-2 dashboard-table">
                  <table class="table table-bordered table-hover">
                    <thead class="thead-dark">
                      <tr>
                        <th>Employee Name</th>
                        <th>Mobile No</th>
                        <th>Job Tiitle</th>
                        <th>Position</th>
                        <th>Location</th>
                        <th>Preferred</th>
                        <th>Ratting</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        peopleData.map((d) => {
                          return <tr>
                            <td>{d.EmployeeName}</td>
                            <td>{d.MobileNo}</td>
                            <td>{d.JobTitle}</td>
                            <td>{d.Position}</td>
                            <td>{d.Location}</td>
                            <td>{d.Status === 'Repeat' ? <i class="fa-regular fa-thumbs-up text-info"></i> :'' }</td>
                            <td>
                              <i class="fa fa-star text-warning" aria-hidden="true"></i>
                              <i class="fa fa-star text-warning" aria-hidden="true"></i>
                              <i class="fa fa-star text-warning" aria-hidden="true"></i>
                              <i class="fa fa-star text-warning" aria-hidden="true"></i>
                              <i class="fa fa-star text-warning" aria-hidden="true"></i>
                            </td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-3 pt-3'></div>



      {(peopleQuitModalShow) ? (<>
        <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">

          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div class="modal-header d-block">
                <div class="d-flex">
                  <h5 className="modal-title"><span className='text-danger'>Quit Compnay : </span>{modalPopUpTitle}</h5>
                  <button
                    type="button"
                    className="modal-close-button ms-auto"
                    onClick={onClickPeopleQuitModalClose}
                  >
                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                  </button>
                </div>
                <div class="d-flex">
                  <span class="modal-sub-title pt-2 pe-4">Enter deatils for quit compnay</span>
                </div>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label id="lblDescription" className="field-label" htmlFor="txtDescription">Enter Note</label>
                    <InputGroup
                      InputGroupClasses="mb-3"
                      icon={<i className="fa fa-pencil-square-o"></i>}
                      control={
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"></textarea>
                      } />
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label id="lblDescription" className="field-label" htmlFor="txtDescription">Preferred</label>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                      <label class="form-check-label" for="flexSwitchCheckDefault">Yes</label>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12">
                    <label id="lblDescription" className="field-label" htmlFor="txtDescription">Feed Back</label>
                    <div class="rating">
                      <input type="radio" id="star5" name="rating" value="5" />
                      <label class="star" for="star5" title="Awesome" aria-hidden="true"></label>
                      <input type="radio" id="star4" name="rating" value="4" />
                      <label class="star" for="star4" title="Great" aria-hidden="true"></label>
                      <input type="radio" id="star3" name="rating" value="3" />
                      <label class="star" for="star3" title="Very good" aria-hidden="true"></label>
                      <input type="radio" id="star2" name="rating" value="2" />
                      <label class="star" for="star2" title="Good" aria-hidden="true"></label>
                      <input type="radio" id="star1" name="rating" value="1" />
                      <label class="star" for="star1" title="Bad" aria-hidden="true"></label>
                    </div>
                  </div>
                </div>


              </div>
              <div className="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={onClickPeopleQuitModalClose}>Cancel</button>
                <button type="button" class="btn btn-primary">Submit</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show">
        </div>
      </>) : <></>}




      {/* <div className='row my-3 px-md-3'>
        <div className='col-md-12 col-lg-6'>

          <div className='table-wrapper'>
            <div className='table-header'>
              <div className='d-flex justify-content-between'>
                <h4>header</h4>
                <img className='table-header-image ' src={job1Img} alt='not found' />

              </div>
            </div>
            <div className='table-container'>
              <div className='d-flex justify-content-between'>
                <p className='table-title'>
                  <i className='fa  fa-briefcase me-2' />
                  Request job
                </p>
                <div className='float-end'>
                  <button className='btn btn-outline-secondary' onClick={onClickJobRequest}>
                    <i class="fa-solid fa-xl  fa-person-circle-plus" ></i>
                  </button>
                </div>
              </div>
              <div class="table-responsive mt-2">
                <table class="table table-bordered table-hover">
                  <thead class="thead-dark">
                    <tr>
                      <th>Job Title</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Location</th>
                      <th>Shift</th>
                      <th>No Applicant</th>
                    </tr>
                  </thead>
                  <tbody>
                    {JobTableData}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className='table-container mt-4'>
            <p className='table-title'>
              <i className='fa  fa-briefcase me-2' />
              Current job
            </p>
            <div class="table-responsive mt-2">
              <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th>Job Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Location</th>
                    <th>Shift</th>
                    <th>No Applicant</th>
                  </tr>
                </thead>
                <tbody>
                  {JobTableData}
                </tbody>
              </table>
            </div>
          </div>

          <div className='table-container mt-4'>
            <p className='table-title'>
              <i className='fa  fa-briefcase me-2' />
              Completed Job
            </p>
            <div class="table-responsive mt-2">
              <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th>Job Title</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Location</th>
                    <th>Shift</th>
                    <th>No Applicant</th>
                  </tr>
                </thead>
                <tbody>
                  {JobTableData}
                </tbody>
              </table>
            </div>
          </div>

        </div>

        <div className='col-md-12 col-lg-6 mt-3 mt-lg-0 mb-4'>
          <div className='table-container'>
            <div className='d-flex justify-content-between'>
              <p className='table-title'>
                <i className='fa fa-user-clock me-2' />
                Upcoming People
              </p>
            </div>
            <div class="table-responsive mt-2">
              <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th>Employee Name</th>
                    <th>Mobile No</th>
                    <th>Job Tiitle</th>
                    <th>Position</th>
                    <th>Location</th>
                    <th>Dress Code</th>
                    <th>Shift</th>
                  </tr>
                </thead>
                <tbody>
                  {TableData}
                </tbody>
              </table>
            </div>
          </div>

          <div className='table-container mt-4'>
            <p className='table-title'>
              <i className='fa fa-user-check me-2' />
              Active People
            </p>
            <div class="table-responsive mt-2">
              <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th>Employee Name</th>
                    <th>Mobile No</th>
                    <th>Job Tiitle</th>
                    <th>Position</th>
                    <th>Location</th>
                    <th>Dress Code</th>
                    <th>Shift</th>
                  </tr>
                </thead>
                <tbody>
                  {TableData}
                </tbody>
              </table>
            </div>
          </div>

          <div className='table-container mt-4'>
            <p className='table-title'>
              <i className='fa fa-users me-2' />
              Old People
            </p>
            <div class="table-responsive mt-2">
              <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th>Employee Name</th>
                    <th>Mobile No</th>
                    <th>Job Tiitle</th>
                    <th>Position</th>
                    <th>Location</th>
                    <th>Dress Code</th>
                    <th>Shift</th>
                  </tr>
                </thead>
                <tbody>
                  {TableData}
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div> */}




    </>
  )
}

export default Dashboard