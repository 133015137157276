import React from 'react'
import bagCheckImg from './../../assets/images/clipart/bag-check.svg';


function PeopleDetailSubCard(props) {
  return (
    <div className="people-detail-card-sub">
      <div className='d-flex justify-content-between'>
        <div className="people-detail-card-code">{props.code}</div>
      </div>
      <div className='clearfix'></div>
      <div className="people-detail-card-date">Request Date: {props.requestDate}</div>
      <div className="row py-3">
        <div className="col-2">
          <div className="people-detail-card-icon">
            <img src={bagCheckImg} className="w-100" />
          </div>
        </div>
        <div className="col-5">
          <div className="people-detail-card-label">Start Date</div>
          <div className="people-detail-card-text">{props.startDate}</div>
        </div>
        {/* <div className="col-5">
          <div className="people-detail-card-label">Start Time</div>
          <div className="people-detail-card-text">{props.startTime}</div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-8">
          <span className="people-detail-card-label-2">Job Position</span>
          <p className="text-primary">{props.jobPosition}</p>
        </div>
      </div>
    </div>
  )
}

export default PeopleDetailSubCard