import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BajoAPI, fetchStatus } from "../../../api/client";
import { Language, USER_KEY } from '../../../common/constants';
import { toaster} from '../../../component/Controls/toasts/toaster';


const initialState = {
    currentStep: '',
    login: {
        status: fetchStatus.IDLE,
        error: null,
        user: undefined
    },
    logout: {
        status: fetchStatus.IDLE,
        error: null
    },
    register: {
        status: fetchStatus.IDLE,
        error: null,
        user: undefined,
        language: Language.ENGLISH
    },
}

export const accountSlice = createSlice({
    name: 'registerDetails',
    initialState,
    reducers: {
        addCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
        updateLoginStatus: (state) => {
            state.login.status = fetchStatus.IDLE;
        },
        updateLanguage: (state, action) => {
            if (action.payload) {
                state.register.language = action.payload;
            }
            else {
                state.register.language = Language.ENGLISH;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.login.status = fetchStatus.LOADING;
        }).addCase(login.fulfilled, (state, action) => {
            state.login.user = action.payload.data;
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload.data));
            state.login.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(login.rejected, (state, action) => {
            state.login.status = fetchStatus.FAILED;
            state.login.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        }).addCase(logout.pending, (state, action) => {
            state.logout.status = fetchStatus.LOADING;
        }).addCase(logout.fulfilled, (state, action) => {
            state.login.user = undefined;
            state.logout.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success);
        }).addCase(logout.rejected, (state, action) => {
            state.logout.status = fetchStatus.FAILED;
            state.logout.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        })
        .addCase(register.pending, (state, action) => {
            state.register.status = fetchStatus.LOADING;
        }).addCase(register.fulfilled, (state, action) => {
            state.login.user = action.payload.data;
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload.data));
            state.register.status = fetchStatus.SUCCEEDED;
            toaster.success(action.payload.success); 
        }).addCase(register.rejected, (state, action) => {
            state.register.status = fetchStatus.FAILED;
            state.register.error = action.error.message;
            toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { addCurrentStep, updateLoginStatus, updateLanguage } = accountSlice.actions;


export const login = createAsyncThunk('accounts/login', async (loginModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Identity/login', loginModel);
        return {
            data: response.data,
            success: response.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }


});

export const logout = createAsyncThunk('accounts/logout', async (logoutModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Account/logout', logoutModel);
        return {
            data: response.data,
            success: response.success
        };

    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const register = createAsyncThunk('accounts/register', async (registerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Identity/register', registerModel);
        return {
            data: response.data,
            success: response.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export default accountSlice.reducer;

export const selectLoginStatus = state => state.accounts.login.status;
export const selectLoginError = state => state.accounts.login.error;

export const selectLoggedInUser = state => {
    let user = state.accounts.login.user;
    if (!user) {
        user = JSON.parse(localStorage.getItem(USER_KEY));
    }
    return user;
};

export const selectLogoutStatus = state => state.accounts.logout.status;
export const selectLogoutError = state => state.accounts.logout.error;

export const selectLanguage = state => state.accounts.register.language;
export const selectRegisterStatus = state => state.accounts.register.status;
export const selectRegisterError = state => state.accounts.register.error;