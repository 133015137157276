import React from 'react';
import noDataImg from './../../assets/images/clipart/no-data.svg';

function DataNotFoundCard(props) {
    return (
        <div className={props.containerClassOverriding ? props.containerClassOverriding : 'data-not-found-container'}>
            <div className="data-not-found">
                <img src={noDataImg} />
                <p className="title">{props.title ? props.title : 'No records found'}</p>
                <p className="description">{props.description ? props.description : 'Please refresh search filters'}</p>
            </div>
        </div>
    )
}

export default DataNotFoundCard;