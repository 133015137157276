import React from 'react';
import womanSketchImg from '../../../../assets/images/clipart/woman-sketch.svg';

function WelcomeCard() {
    return (
        // <div className="welcome-card">
        //     <div className='row'>
        //         <div className="col-3">

        //         </div>
        //         <div className="col-5">
        //             <h3>Good Morning, Allen</h3>
        //             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
        //         </div>
        //         <div className="col-4">
        //         45
        //         Total jobs

        //         $2,000.00
        //         Total Balance
        //         </div>
        //     </div>
        // </div>


        // <div className='heading-card'>
        //     <div className='d-flex'>
        //         <img src={womanSketchImg} alt='not found' />
        //         <div className=''>
        //             <h2 className='title'>Good Morning, Allen</h2>
        //             <p className='sub-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>

        //             <div className='d-flex'>
        //                 <div class="d-flex flex-column"> <span class="">45</span> <span class="text-secondary">Total Job</span> </div>
        //                 <div class="d-flex flex-column ms-5"> <span class="">$2,000.00</span> <span class="text-secondary">Total Revenue</span> </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <div className='welcome-card'>
            <div className='row'>
                <div className='col-md-4'>
                    <img src={womanSketchImg} alt='not found' />
                </div>
                <div className='col-md-5'>
                    <h2 className='title'>Good Morning, Allen</h2>
                    <p className='sub-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                    </div>
                <div className='col-md-3'>
                    <div className='d-flex'>
                        <div class="d-flex flex-column"> <span class="">45</span> <span class="text-secondary">Total Job</span> </div>
                        <div class="d-flex flex-column ms-5"> <span class="">$2,000.00</span> <span class="text-secondary">Total Revenue</span> </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeCard;