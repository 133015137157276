import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
// import './css/logCard.css';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { toLocalDate, _getContent, formatraw, toLocalDateOnly } from '../../../common/common';
import noPhoto from '../../../assets/images/clipart/nophoto.png';
import DataNotFoundCard from '../../Card/DataNotFoundCard';
import { positionsData } from '../../../features/people/screens/masterData';

function LogCard(props) {

    const _getPositionText = (id) => {
        if (positionsData) {
            let position = positionsData.find(p => p.value === id);
            if (position && position.text) {
                return position.text;
            }
        }
        return "N/A";
    }

    const getStatus = (logValue, logType) => {

        let statusText;

        if (logValue === 0) {
            statusText = statusText = <><span class="p-changed"> {logType} </span></>;;
        }
        else if (logValue === 1) {
            statusText = <><span class="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-gray"> {logType} </span></span></>;
        }
        else if (logValue === 2) {
            statusText = <><span class="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-green"> {logType} </span></span></>;
        }
        else if (logValue === 3) {
            statusText = <><span class="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-success"> {logType} </span></span></>;
        }
        else if (logValue === 4) {
            statusText = <><span class="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-danger"> {logType} </span></span></>;
        }
        else if (logValue === 5 || logValue === 6) {
            statusText = <><span class="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill status-dnu"> {logType} </span></span></>;
        }
        else if (logValue === 7) {
            statusText = <><span class="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill bg-gold"> {logType} </span></span></>;
        }
        else if (logValue === 8 || logValue === "8") {
            statusText = <><span class="p-changed"> Changed status to </span><span className="p-status"><span className="badge rounded-pill status-undispatch"> {logType} </span></span></>;
        }
        else if (logValue === -1) {
            statusText = <><span class="p-changed"> Added Note </span></>;
        }
        else if (logValue === -2) {
            statusText = <><span className="p-status"> <span className="badge rounded-pill bg-light-orange"> {logType} </span></span><span class="p-changed"> Applicant </span></>;
        }
        else if (logValue === -3) {
            statusText = <><span class="p-changed"> Applicant </span><span className="p-status"><span className="badge rounded-pill bg-blue"> {logType} </span></span></>;
        }
        else if (logValue === -4) {
            statusText = <><span class="p-changed"> Set </span><span className="p-status"><span className="text-orange"> {logType} </span></span></>;
        }
        else if (logValue === -9) {
            statusText = <><span class="p-changed"> has {logType}</span></>;
        }
        else {
            statusText = <span><span class="text-muted statusfont"></span><span className="text-dark cardtitle"> {logType} </span></span>;
        }
        return statusText;
    };

    const getStatusIcon = (logValue) => {
        let statusIcon;
        if (logValue === -9) {
            statusIcon = <div className="tracking-icon status-intransit bg-light-orange"><span><i class="fa fa-location-dot fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === -4) {
            statusIcon = <div className="tracking-icon status-intransit bg-orange"><span><i class="fa fa-person-walking-arrow-loop-left fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === -2 || logValue === -3) {
            statusIcon = <div className="tracking-icon status-intransit bg-green"><span><i class="fa fa-phone fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === -1) {
            statusIcon = <div className="tracking-icon status-intransit bg-blue"><span><i class="fa fa-pen-to-square fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 0) {
            statusIcon = <div className="tracking-icon status-intransit bg-create"><span><i class="fa fa-bolt fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 1 || logValue === 2 || logValue === 3 || logValue === 4 || logValue === 5 || logValue === 7) {
            statusIcon = <div className="tracking-icon status-intransit bg-purple"><span><i class="fa fa-stamp fa-md" aria-hidden="true"></i></span></div>;
        }
        else if (logValue === 8 || logValue === '8') {
            statusIcon = <div className="tracking-icon status-intransit status-undispatch"><span><i class="fa fa-person-walking-arrow-right fa-md" aria-hidden="true"></i></span></div>;
        }
        else {
            statusIcon = <div className="tracking-icon status-intransit bg-secondary"><span><i class="fa fa-info fa-md" aria-hidden="true"></i></span></div>;
        }
        return statusIcon;
    }

    const getJobIcon = () => {
        let statusIcon;
        statusIcon = <div className="tracking-icon status-intransit bg-green"><span><i class="fa fa-briefcase fa-md" aria-hidden="true"></i></span></div>;
        return statusIcon;
    }

    const [logs, setLogs] = useState();

    // useEffect(() => {
    //     let _statusLogs = props.statusLogs;
    //     let _logs;
    //     if (isObjectArray(_statusLogs) && _statusLogs.length > 0) {
    //         // _logs = _statusLogs.map((log, index) => (
    //         _logs = _statusLogs.filter((f) => f.LogValue == 3).map((log, index) => (
    //             console.log(log),
    //             <>
    //                 {log &&
    //                     <div className="tracking-item">
    //                         {getStatusIcon(log.LogValue)}
    //                         <div className="tracking-content">
    //                             <p>test 
    //                                 <span className="p-name">{log.UserName != null ? log.UserName : ""}</span>
    //                                 {log && getStatus(log.LogValue, log.LogType)}
    //                             </p>
    //                             <p className="p-subtext">{log && _getContent(log.Content, log.LogValue)}</p>
    //                             {log && log.Client && <p className="p-dark-gray">Client : <span className="p-light-gray">{log.Client}</span></p>}
    //                             {log && log.PayRates && <p className="p-dark-gray">Pay Rates : <span className="p-light-gray">{log && log.PayRates}</span></p>}
    //                             <p className="p-light-gray">{log && toLocalDate(log.TimeStamp)}</p>
    //                         </div>
    //                     </div>
    //                 }
    //             </>
    //         ));
    //         setLogs(_logs);
    //     }
    //     else {
    //         setLogs(<DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title="" description="" />);
    //     }
    // }, [props.statusLogs]);


    useEffect(() => {
        let _jobsLog = props.jobsLogs;
        let _logs;
        if (isObjectArray(_jobsLog) && _jobsLog.length > 0) {
            _logs = _jobsLog.map((log, index) => (
                console.log(log),
                <>
                    {log &&
                        <div className="tracking-item">
                            {getJobIcon()}
                            <div className="tracking-content">
                                <p>
                                    <span className="p-name">{log.Position != null ? _getPositionText(log.Position) : ""}</span>
                                    {/* {log && getStatus(log.LogValue, log.LogType)} */} &nbsp; at &nbsp;
                                    {log && log.Title}
                                </p>
                                <p className="p-subtext">Success completd job</p>
                                <p className="p-light-gray">{log && toLocalDateOnly(log.StartDate)}</p>
                            </div>
                        </div>
                    }
                </>
            ));
            setLogs(_logs);
        }
        else {
            setLogs(<DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title="" description="" />);
        }
    }, [props.jobsLogs]);

    return (
        <>
            {logs}
        </>
    );
}

export default LogCard;