import {
    createSlice,
    createAsyncThunk,
    nanoid
}
    from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import Collection from '../../../component/Controls/paginators/collection';

import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    data: {
        totalItems: 0,
        items: [],
        currentPage: 0,
        status: fetchStatus.IDLE,
        error: null,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: undefined,
        isOpened: false
    },
    recentClickedApplicant: {
        id: undefined
    },
    jobsApplicant: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
    documentDownload: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined
    },
};

export const getAllApplicants = createAsyncThunk('applicants/getAllApplicants', async (applicantModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post('Gateway', applicantModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : "[]";
        let applicants;
        if (isJSON(data)) {
            applicants = JSON.parse(data);
        }
        return {
            page: applicantModel.page,
            applicants: applicants,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getApplicantById = createAsyncThunk('applicants/getApplicantById', async (applicantModel, { rejectWithValue }) => {
    try {
        // const response = await BajoAPI.post(`Gateway`, applicantModel.model, getAxiosRequestConfig());
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());

        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
            // console.log('if data isJSON -->', applicant);
            // return applicant;
        }
        return {
            page: applicantModel.page,
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});

export const getJobsByApplicantId = createAsyncThunk('applicants/getJobsByApplicantId', async (applicantModel, { rejectWithValue }) => {
    try {
        // const response = await BajoAPI.post(`Gateway`, applicantModel.model, getAxiosRequestConfig());
        const response = await BajoAPI.post(`Gateway`, applicantModel, getAxiosRequestConfig());

        const data = response.data ? response.data.data : undefined;
        let applicant = undefined;
        if (data && isJSON(data)) {
            applicant = JSON.parse(data);
            // console.log('if data isJSON -->', applicant);
            // return applicant;
        }
        return {
            page: applicantModel.page,
            applicant: applicant,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export const downloadDocument = createAsyncThunk('reviewers/downloadDocument', async (reviewerModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, reviewerModel, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let documentObj = undefined;
        if (data && isJSON(data)) {
            documentObj = JSON.parse(data);
        }
        return {
            documentObj: documentObj,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export const peopleSlice = createSlice({
    name: 'peoples',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        updateSingleData: (state, _data) => {
            state.single.isOpened = false;
            state.single.refreshed = nanoid();
            if (_data.payload) {
                state.single.data = Object.assign({}, _data.payload);
                let _item = state.data.records.Get(state.data.currentPage);
                if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                    let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.id);
                    let newRecords = _item.records;//.filter(a => a.id !== _data.payload.id);
                    let _applicant = {
                        id: _data.payload.id,
                        FirstName: _data.payload.FirstName.Data,
                        LastName: _data.payload.LastName.Data,
                        ApplicationDate: _data.payload.ApplicationDate,
                        Email: _data.payload.EmailAddress.Data,
                        PhoneNumber: _data.payload.PhoneNumber.Data,
                        Location: _data.payload.Location.Data,
                        Status: _data.payload.Status,
                        ImageData: _data.payload.PhotoPath.Data,
                        IsOpened: _data.payload.IsOpened
                    };
                    newRecords[_updatedObjectIndex] = _applicant;
                    _item.records = newRecords;
                    let records = new Collection();
                    records.Add(_item.key, newRecords);
                    state.data.records.Remove(_item.key);
                    records.Concat(state.data.records);
                    state.data.records = records;
                }
            }
            else {
                state.single.data = undefined;
            }
        },
        updateCurrentPage: (state, page) => {
            state.data.currentPage = page.payload;
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateIsFiltered: (state) => {
            state.data.isFiltered = true;
        },
        updateSingleApplicant: (state, _data) => {
            if (_data.payload) {
                state.single.data = Object.assign({}, _data.payload);
            }
        },
        updateRecentClickedApplicant: (state, _data) => {
            state.recentClickedApplicant.id = _data.payload;
        },
        updateDocumentDownloadStatus: (state) => {
            state.documentDownload.status = fetchStatus.IDLE;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllApplicants.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getAllApplicants.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.applicants.totalItems;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.applicants.items);
            if (!state.data.isFiltered) {
                _records.Concat(state.data.records);
            }
            else {
                state.data.firstRecords = new Collection();
                state.data.currentPage = 0;
            }
            state.data.isFiltered = false;
            state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            // toaster.success(action.payload.success);
        }).addCase(getAllApplicants.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.error = action.error.message;
            // toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getApplicantById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getApplicantById.fulfilled, (state, action) => {
            state.single.data = action.payload.applicant;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.applicant);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            state.single.isOpened = true;
            // toaster.success(action.payload.success);
        }).addCase(getApplicantById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            // toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getJobsByApplicantId.pending, (state, action) => {
            state.jobsApplicant.status = fetchStatus.LOADING;
        }).addCase(getJobsByApplicantId.fulfilled, (state, action) => {
            state.jobsApplicant.data = action.payload.applicant;
            state.jobsApplicant.status = fetchStatus.SUCCEEDED;
            // toaster.success(action.payload.success);
        }).addCase(getJobsByApplicantId.rejected, (state, action) => {
            state.jobsApplicant.status = fetchStatus.FAILED;
            state.jobsApplicant.error = action.error.message;
            // toaster.error(action.payload ? action.payload.error : "");
        }).addCase(downloadDocument.pending, (state, action) => {
            state.documentDownload.status = fetchStatus.LOADING;
        }).addCase(downloadDocument.fulfilled, (state, action) => {
            state.documentDownload.data = action.payload.documentObj;
            state.documentDownload.status = fetchStatus.SUCCEEDED;
            // toaster.success(action.payload.success);
        }).addCase(downloadDocument.rejected, (state, action) => {
            state.documentDownload.status = fetchStatus.FAILED;
            state.documentDownload.error = action.error.message;
            // toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const { updateSingleStatus, updateSingleData, updateCurrentPage,
    updateStatus, updateIsFiltered, loadSingleData, 
    updateSingleApplicant, updateRecentClickedApplicant,
    updateDocumentDownloadStatus } = peopleSlice.actions;

export default peopleSlice.reducer

export const selectAllApplicants = state => {
    return state.peoples.data.items;
}

export const selectAllApplicantRecords = state => {
    return state.peoples.data.records;
}

export const selectTotalItems = state => state.peoples.data.totalItems;

export const selectStatus = state => state.peoples.data.status;

export const selectError = state => state.peoples.data.error;


export const selectApplicantById = (state) => {
    return state.peoples.single ? state.peoples.single.data : undefined;
}

export const selectSingleStatus = state => state.peoples.single.status;

export const selectSingleError = state => state.peoples.single.error;



export const selectCurrentPage = state => {
    return state.peoples.data.currentPage;
}

export const selectFirstRecord = (state, currentPage) => {
    return state.peoples.data.firstRecords.Get(currentPage);
}

export const selectSingleRefreshed = state => state.peoples.single.refreshed;
export const selectSingleOpened = state => state.peoples.single.isOpened;

export const selectRecentClickedApplicant = state => state.peoples.recentClickedApplicant.id;

export const selectJobsApplicantStatus = state => state.peoples.jobsApplicant.status;
export const selectJobsByApplicantId = state => state.peoples.jobsApplicant.data;

export const selectDocumentDownloadStatus = state => state.peoples.documentDownload.status;
export const selectDocumentDownloadData = state => state.peoples.documentDownload.data;