import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import PeopleCard from '../../../component/Card/PeopleCard';
import { _getDateText, toLocalDate, toLocalDateOnly } from '../../../common/common';
import PageContainer from '../../../component/Controls/paginators/paginator/pageContainer';
import Collection from '../../../component/Controls/paginators/collection';
import { accountService } from '../../accounts/services/accountService';
import { getAllApplicants, selectAllApplicantRecords, selectCurrentPage, selectStatus, selectTotalItems, updateCurrentPage, updateIsFiltered, updateRecentClickedApplicant, updateStatus } from '../reducerSlices/peopleSlice';
import { selectDefaultFilter, selectEmploymentTypeFilter, selectFilterId, selectPositionFilter, selectRefreshData, selectShiftFilter, selectTotalMoreFilterCount, selectWhereClause, selectWhereClauseChanged, updateLocationFilter } from '../../../component/Controls/filters/reducerSlices/filterSilce';
import { fetchStatus } from '../../../api/client';
import ShiftFilter from '../../../component/Controls/filters/shiftFilter';
import { onEmploymentTypeChanged, onPositionChanged, onShiftChanged } from '../services/filterHandlers';
import PositionFilter from '../../../component/Controls/filters/positionFilter';
import EmploymentTypeFilter from '../../../component/Controls/filters/employmentTypeFilter';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';


function People() {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = accountService.getAuthenticatedUser();

  const [applicantsLoaded, setApplicantsLoaded] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const currentPage = useSelector(selectCurrentPage);

  const totalPageItems = useSelector(selectTotalItems);
  const applicants = useSelector(selectAllApplicantRecords);
  const applicantsRequestStatus = useSelector(selectStatus);

  // const sort = useSelector(selectSort);
  const sort = { by: 1, ascending: false };

  const whereClause = useSelector(selectWhereClause);
  const whereClauseChanged = useSelector(selectWhereClauseChanged);
  const defaultFilter = useSelector(selectDefaultFilter);
  const refreshData = useSelector(selectRefreshData);

  const [appRecords, setAppRecords] = useState(new Collection());
  const [dummyCards, setDummyCards] = useState([]);

  const totalMoreFilterCount = useSelector(selectTotalMoreFilterCount);

  // const userFiltersStatus = useSelector(selectUserFiltersStatus);
  const [customFilters, setCustomFilters] = useState([]);
  const _filterId = useSelector(selectFilterId);
  const [filterId, setFilterId] = useState("");
  // const userFilters = useSelector(selectUserFilters);
  // const defaultFilters = useSelector(selectDefaultFilters);

  // const removeFilterStatus = useSelector(selectRemoveFilterStatus);
  // const removeFilterId = useSelector(selectRemoveFilterId);

  const [isFiltered, setIsFiltered] = useState(false);

  const [applyMoreFilters, setApplyMoreFilters] = useState(false);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 1,
        title: "People Search"
      }));
      setPageLoaded(true);
    }
  });


  const _loadApplicants = async (page, offset, limit, whereClause, sort) => {
    setIsFiltered(false);
    await dispatch(getAllApplicants({
      page: page,
      model: {
        client: "test",
        serviceType: "TENANTUSER",
        operation: "GET",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: limit,
            where: whereClause,
            sort: sort
          })
        }
      }
    }));
  }

  useEffect(() => {
    // if (applicantsRequestStatus === fetchStatus.IDLE || applicantsLoaded === false) {
    if (applicantsRequestStatus === fetchStatus.IDLE) {
      let loc = user ? user.locationId : "";
      let where = JSON.parse(JSON.stringify(whereClause));
      where.location.value.push(loc);
      dispatch(updateLocationFilter({
        // locations: locationOptions,
        locations: loc,
        value: loc
      }));
      dispatch(updateIsFiltered());
      _loadApplicants(0, 0, 10, where, sort);
      setApplicantsLoaded(true);
    }
  }, [applicantsRequestStatus, dispatch, applicantsLoaded]);


  let applicantCards;
  let _dummyCards = [];

  let _appRecords = new Collection();

  let applicantCardsHorizontalCard;

  useEffect(() => {
    if (applicantsRequestStatus === fetchStatus.SUCCEEDED || applicantsRequestStatus === fetchStatus.DONE) {
      let _records = applicants.Items;
      console.log(applicants.Items)
      if (applicants.Items && applicants.Items.length > 0) {
        for (let i = 0; i < applicants.Items.length; i++) {
          let item = applicants.Items[i];
          applicantCards = item.records.map((applicant, index) => (
            <PeopleCard
              profilePhoto={applicant.ImageData}
              fullName={applicant.FirstName + " " + applicant.LastName}
              noOfJobs={7}
              dateOfBirth={toLocalDateOnly(applicant.DateOfBirth)}
              position={"Product Manager, Accounting"}
              workingSince={toLocalDateOnly(new Date())}
              Experience={"1 Year"}
              status={applicant.Gender === 'Male' ? 'Repeate' : 'New'}
              gender={"Male"}

              onClick={(e) => onClickApplicant(e, applicant.id)}
            />
          ));
          _appRecords.Add(item.key, applicantCards);
        }
        setAppRecords(_appRecords);
        dispatch(updateStatus());
      }
      dispatch(updateStatus());
    }
    if (applicants && applicants.Items.length > 0) {
      let item = applicants.Get(currentPage);
      let _applicants = item ? item.records : [];
      if (_applicants && Array.isArray(_applicants) && _applicants.length > 0) {
        let _applicant = _applicants[0];
        if (_applicant && _applicant.id) {
          // if (firstApplicant && firstApplicant.records) {
          //   dispatch(loadSingleData(firstApplicant.records));
          // }
          // else {
          //   _loadApplicant(_applicant.id, currentPage);
          // }
        }
      }
      else {
        // dispatch(updateSingleData(undefined));
      }
    }
  }, [applicantsRequestStatus, currentPage]);

  const onPageChanged = async (p, l) => {
    dispatch(updateCurrentPage(p));
    if (l) {
      await onLoadItems(p);
    }
  };

  const onLoadItems = async (p) => {
    await _loadApplicants(p, p * 10, 10, whereClause, sort);
  };

  const onFilterClicked = async (e) => {
    e.preventDefault();
    await dispatch(updateIsFiltered());
    _loadApplicants(0, 0, 10, whereClause, sort);
  };

  const onClickApplicant = (e, applicant) => {
    dispatch(updateRecentClickedApplicant(applicant));
    history.push('people/details')
  }

  return (
    <>
      <div className="app-container">

        {/* Filter Bar */}
        <div className="filter-bar p-3">
          <button className={`c-btn mx-1 ${filterId === "2D98B0C0-5E22-45FE-BA2C-D9E408D982BA" ? "" : "c-btn-gray"}`} type="button">All</button>
          <button className={`c-btn mx-1 ${filterId === "3343FE89-9898-4CB0-9369-13AC9AD5D40B" ? "" : "c-btn-gray"}`} type="button">New</button>
          <button className={`c-btn mx-1 ${filterId === "1C634E95-61BB-4831-9983-873743395DE6" ? "" : "c-btn-gray"}`} type="button">Repeate</button>
          <button className={`c-btn mx-1 ${filterId === "ACFDF03E-D97D-4F47-9258-156C420E7F6D" ? "" : "c-btn-gray"}`} type="button">Part Tile</button>
          <button className={`c-btn mx-1 ${filterId === "ACFDF03E-D97D-4F47-9258-156C420E7F6D" ? "" : "c-btn-gray"}`} type="button">Full Tile</button>
          <div className="float-end">
            {isFiltered &&
              <button type="button" className="c-btn dark-btn position-relative" onClick={(e) => onFilterClicked(e)}>
                <i className="fa fa-search me-2" aria-hidden="true"></i>Search
              </button>
            }
            <button type="button" className="c-btn white-btn position-relative ms-3" onClick={() => setApplyMoreFilters(true)}>
              <i className="fa fa-filter me-2" aria-hidden="true"></i>Filters
              <span className={`position-absolute top-0 start-100 translate-middle ${totalMoreFilterCount > 0 ? 'p-1' : 'p-0'} bg-danger border border-light badge bg-danger`}>
                <span className="">{totalMoreFilterCount > 0 ? totalMoreFilterCount : ''}</span>
              </span>
            </button>
          </div>
        </div>

        <div className="filter-bar p-3 pt-0">

          <input
            type="text"
            id="search"
            name="search"
            // value=""
            className="form-control people-search-box filter"
            placeholder="Search"
            autocomplete="off"
          // onChange={(e) => onChange(e)}
          />

          <ShiftFilter shiftFilterSelector={selectShiftFilter} onShiftChanged={(e, v, shifts) => { onShiftChanged(e, v, shifts); setIsFiltered(true); }} />
          <PositionFilter positionFilterSelector={selectPositionFilter} onPositionChanged={(e, v, positions) => { onPositionChanged(e, v, positions); setIsFiltered(true); }} />
          {/* <WorkStatusFilter workStatusFilterSelector={selectWorkStatusFilter} onWorkStatusChanged={(e, v, workStatuses) => { onWorkStatusChanged(e, v, workStatuses); setIsFiltered(true); }} /> */}
          <EmploymentTypeFilter employmentTypeFilterSelector={selectEmploymentTypeFilter} onEmploymentTypeChanged={(e, v, employmentTypes) => { onEmploymentTypeChanged(e, v, employmentTypes); setIsFiltered(true); }} />
          {/* <CalledOnFilter calledOnFilterSelector={selectCalledOnFilter} onCalledOnChanged={(e, c) => { onCalledOnChanged(e, c); setIsFiltered(true); }} /> */}



          {/* <div className="float-end">
              <div className="sort-btn-group">
                <button className={`c-btn sort-btn-transparent ${peopleListView && 'sort-btn-active'}`} type="button" onClick={() => setPeopleListView(true)} ><img src={peopleListView ? listViewImg : listViewLightImg} /></button>
                <button className={`c-btn sort-btn-transparent ${!peopleListView && 'sort-btn-active'}`} type="button" onClick={() => setPeopleListView(false)}><img src={peopleListView ? cardViewLightImg : cardViewImg} /></button>
              </div>
            </div> */}


        </div>
        {/* Filter Bar End */}

        {/* <PageContainer
          currentPage={currentPage}
          totalPageItems={totalPageItems}
          pageRecords={!peopleListView ? appRecords : appRecordsHorizontalCard}
          isItemsLoading={applicantsRequestStatus}
          itemsLoadingContent={dummyCards}
          onPageChanged={(p, l) => onPageChanged(p, l)}
          infinite={true}
        /> */}

        {/* <PageContainer
          currentPage={currentPage}
          totalPageItems={totalPageItems}
          pageRecords={appRecords}
          isItemsLoading={applicantsRequestStatus}
          // itemsLoadingContent={dummyCards}
          // onPageChanged={(p, l) => onPageChanged(p, l)}
          infinite={true}
        /> */}

        <PageContainer
          currentPage={currentPage}
          totalPageItems={totalPageItems}
          pageRecords={appRecords}
          isItemsLoading={applicantsRequestStatus}
          itemsLoadingContent={dummyCards}
          onPageChanged={(p, l) => onPageChanged(p, l)}
          infinite={true}
        />

        {/* <PeopleCard
          fullName={"Alina P"}
          noOfJobs={7}
          dateOfBirth={toLocalDateOnly(new Date("1998-03-27"))}
          position={"Product Manager, Accounting, Product Manager"}
          workingSince={toLocalDateOnly(new Date())}
          Experience={"1 Year"}
          status={"New"}
          gender={"Male"}

          onClick={(e) => { alert('clicked') }}
        /> */}



      </div>
    </>
  )
}

export default People;