import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLayoutDetail } from '../layouts/reducerSlices/layoutSlice';

// import logoImg from './../../assets/images/logo/logo.svg';
//menu Image

import jobOrderImg from './../../assets/images/menu/job-order.svg';
import jobOrderTransparentImg from './../../assets/images/menu/job-order-transparent.svg';
import peopleImg from './../../assets/images/menu/people.svg';
import peopleTransparentImg from './../../assets/images/menu/people-transparent.svg';



function SideNavBar() {

  const history = useHistory();

  const onClickDashboard = (e) => {
    history.push('/dashboard');
  }

  const onClickPeople = () => {
    history.push('/people');
  }

  const onClickJobOrder = () => {
    history.push('/jobOrder');
  }

  const [selectedLink, setSelectedLink] = useState(0);

  const layoutDetail = useSelector(selectLayoutDetail);

  useEffect(() => {
    if (layoutDetail) {
      setSelectedLink(layoutDetail.page);
    }
  });

  return (
    <div
      className="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column"
      id="sidebar">
      <ul className="nav flex-column text-white w-100">
        <li href="#" className="nav-link">
          <div className="nav-item-box">
            {/* <img src={logoImg} alt="logo"></img> */}
            <span className='clientportal-text-logo'>CP</span>
          </div>
        </li>
        {/* <li href="#" className="nav-link mt-3">
          <div className="nav-item-box" onClick={onClickDashboard}>
            <div className={`nav-item-box-img ${selectedLink === 12 ? 'active' : ''}`}>
              <span><i class={`fa fa-xl fa-dashboard ${selectedLink === 12 ? 'text-white' : ''}`} aria-hidden="true"></i></span>
            </div>
            <div className={`nav-item-box-text ${selectedLink === 12 ? 'active' : ''}`}>Dash <br></br>Board</div>
          </div>
        </li> */}
        <li href="#" className="nav-link">
          <div className="nav-item-box" onClick={onClickJobOrder}>
            <div className={`nav-item-box-img ${selectedLink === 2 ? 'active' : ''}`}>
              <img src={selectedLink === 5 ? jobOrderTransparentImg : jobOrderImg} ></img>
            </div>
            <div className={`nav-item-box-text ${selectedLink === 2 ? 'active' : ''}`}>Job Order</div>
          </div>
        </li>
        <li href="#" className="nav-link">
          <div className="nav-item-box" onClick={onClickPeople}>
            <div className={`nav-item-box-img ${selectedLink === 1 ? 'active' : ''}`}>
              <img src={selectedLink === 1 ? peopleTransparentImg : peopleImg} ></img>
            </div>
            <div className={`nav-item-box-text ${selectedLink === 1 ? 'active' : ''}`}>People</div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default SideNavBar