import React, { useEffect, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';

// import './css/pageNavigation.css';

function PageNavigation(props) {
    let pageNumbersGroups = [];
    let pageNumbers = [];

    const [selectedPage, setSelectedPage] = useState(props.selectedPage ? props.selectedPage : 0);
    const [currentGroup, setCurrentGroup] = useState(0);

    useEffect(() => {
        setSelectedPage(props.selectedPage);
        if (props.selectedPage === 0) {
            setCurrentGroup(0);
        }
    }, [props.selectedPage]);

    let pageNumberCount = 0;
    for (let i = 0; i < props.totalPages; i++) {
        pageNumberCount++;
        pageNumbers.push(
            // <span key={nanoid()} className={`page-number ${selectedPage === i ? "page-number-selected" : ""}`} onClick={() => props.onPageClicked(i)}> {i + 1}</span >
            <li key={nanoid()}><a href="#" className={`${selectedPage === i ? "active" : ""}`} onClick={() => props.onPageClicked(i)}>{i + 1}</a></li>
        );
        if (pageNumberCount === props.totalVisiblePageNumbers || i === props.totalPages - 1) {
            pageNumbersGroups.push(
                <React.Fragment>
                    {pageNumbers}
                </React.Fragment>
            );
            pageNumbers = [];
            pageNumberCount = 0;
        }
    }



    const onPreviousGroupClicked = () => {
        let prevPage = selectedPage - 1;
        if (prevPage < 0) {
            return;
        }
        else if (prevPage !== 0 && (prevPage + 1) % props.totalVisiblePageNumbers === 0) {
            setCurrentGroup(currentGroup - 1);
        }

        // if (previousGroup < 0) {
        //     return;
        // }
        //props.onPageClicked(previousGroup * props.totalVisiblePageNumbers);
        props.onPageClicked(prevPage);
    }

    const onNextGroupClicked = () => {
        let nextPage = selectedPage + 1;
        if (nextPage > props.totalPages - 1) {
            return;
        }
        else if (nextPage % props.totalVisiblePageNumbers === 0) {
            let nextGroup = currentGroup + 1;
            setCurrentGroup(nextGroup);
        }

        // if (nextGroup > pageNumbersGroups.length - 1) {
        //     return;
        // }
        //props.onPageClicked(nextGroup * props.totalVisiblePageNumbers);
        props.onPageClicked(nextPage);
    }

    return (
        // <div className="row p-2 pt-3 pb-3 pagination">
        //     {/* <div className="col-md-12">
        //         <div className="page-numbers-container">
        //             <span className="page-left" onClick={() => onPreviousGroupClicked()}><i className="fa fa-chevron-left" aria-hidden="true"></i></span>
        //             {pageNumbersGroups[currentGroup]}
        //             <span className="page-right" onClick={() => onNextGroupClicked()}><i className="fa fa-chevron-right" aria-hidden="true"></i></span>
        //         </div>
        //     </div> */}

        //     <div className="d-flex justify-content-between">
        //         {/* <div className="c-table-result">Showing 1 to 8 of 24 entries</div> */}
        //         <div className="c-table-result">{`Showing ${props.totalPages == 0 ? 0 : 1} to ${props.totalPages} of ${props.totalPageItems} entries`}</div>
        //         <div className="d-flex" >
        //             <ul className="c-pagination">
        //                 <li><a href="#" onClick={() => onPreviousGroupClicked()}><i class="fa-solid fa-angle-left" /></a></li>
        //                 {/* <li><a href="#" className="active">1</a></li>
        //                 <li><a href="#">2</a></li>
        //                 <li><a href="#">3</a></li> */}
        //                  {pageNumbersGroups[currentGroup]}
        //                 <li><a href="#" onClick={() => onNextGroupClicked()}><i class="fa-solid fa-angle-right" /></a></li>
        //             </ul>
        //         </div>
        //     </div>

        // </div>

        <tr>
            <td colSpan={props.numberOfColumns}>
                <div className="row p-2 pt-3 pb-3 pagination">
                    <div className="d-flex justify-content-between">
                        <div className="c-table-result">{`Showing ${props.totalPages == 0 ? 0 : 1} to ${props.totalPages} of ${props.totalPageItems} entries`}</div>
                        <div className="d-flex" >
                            <ul className="c-pagination">
                                <li><a href="#" onClick={() => onPreviousGroupClicked()}><i class="fa-solid fa-angle-left" /></a></li>
                                {pageNumbersGroups[currentGroup]}
                                <li><a href="#" onClick={() => onNextGroupClicked()}><i class="fa-solid fa-angle-right" /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
}

export default PageNavigation;