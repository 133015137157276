import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import PeopleDetailCard from '../../../component/Card/PeopleDetailCard';
import { getApplicantById, getJobsByApplicantId, selectApplicantById, selectJobsApplicantStatus, selectJobsByApplicantId, selectRecentClickedApplicant,
  selectSingleStatus as applicantStatus  } from '../reducerSlices/peopleSlice';
import nophoto from './../../../assets/images/clipart/nophoto.png';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { positionsData } from './masterData';
import SortButton from '../../../component/Controls/buttons/sortButton';
import LogCard from '../../../component/Controls/cards/logCard';
import DocumentsCard from '../../../component/Controls/cards/documentsCard';
import { fetchStatus } from '../../../api/client';
import GridLoader from '../../../component/Controls/loaders/gridLoader';


function PeopleDetails() {

  const history = useHistory();
  const dispatch = useDispatch();

  const [pageLoaded, setPageLoaded] = useState(false);

  const applicant = useSelector(selectApplicantById);

  const applicantRequestStatus = useSelector(applicantStatus);

  const recentClickedApplicant = useSelector(selectRecentClickedApplicant);

  const jobsApplicantStatus = useSelector(selectJobsApplicantStatus);
  const jobsApplicant = useSelector(selectJobsByApplicantId);

  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 1,
        title: "People Details"
      }));
      setPageLoaded(true);
    }
  });


  const _loadApplicant = async (id) => {
    await dispatch(getApplicantById({
      client: "test",
      serviceType: "TENANTUSER",
      operation: "GET",
      payload: {
        id: id,
        data: ""
      }
    }));
  }


  const _loadJobsApplicant = async (id) => {
    await dispatch(getJobsByApplicantId({
      client: "test",
      serviceType: "TENANTADMIN",
      operation: "GETJOBSBYAPPLICANTID",
      payload: {
        id: id,
        data: ""
      }
    }));
  }

  useEffect(() => {
    if (recentClickedApplicant === undefined) {
      history.push('../people');
    }

    if (recentClickedApplicant) {
      _loadApplicant(recentClickedApplicant);
      _loadJobsApplicant(recentClickedApplicant);
    }
  }, [recentClickedApplicant]);


  const _getPositionText = (idList) => {
    if (isObjectArray(idList)) {
      let positions = "";
      for (let i = 0; i < idList.length; i++) {
        let position = positionsData.find(p => p.value === idList[i]);
        if (position && position.text) {
          positions += position.text + ", ";
        }
      }
      positions = positions.trim();
      positions = positions.slice(0, -1);
      return positions;
    }
    return "";
  }



  const [latestLog, setLatestLog] = useState(undefined);
  const [logCards, setLogCards] = useState(undefined);
  const [sort, setSort] = useState(false);


  let _logCards;
  useEffect(() => {
    setLatestLog(undefined);
    if (applicant) {


      if (applicant.StatusLogs && Array.isArray(applicant.StatusLogs) && applicant.StatusLogs.length > 0) {
        let _latestLog = applicant.StatusLogs.slice(-1);
        if (_latestLog && Array.isArray(_latestLog) && _latestLog.length === 1) {
          setLatestLog(_latestLog[0]);
        }
      }
      if (applicant && applicant.StatusLogs && Array.isArray(applicant.StatusLogs) && applicant.StatusLogs.length > 0) {

        let logs = [...applicant.StatusLogs];
        setLogCards(logs.reverse());
      }
      else {
        setLogCards(undefined);
      }
    }
    else {
      setLogCards(undefined);
    }
  }, [applicant]);

  const onLogSearch = (e) => {
    let text = e.target.value;
    var items = applicant.StatusLogs.filter((d) => {
      return d.Content.toLowerCase().includes(text.toLowerCase()) || d.LogType.toLowerCase().includes(text.toLowerCase()) || d.UserName.toLowerCase().includes(text.toLowerCase())
    });
    if (!sort) {
      items = items.reverse();
    }
    setLogCards(items);
  }

  const onSort = (e) => {
    setSort(!sort);
    const sortData = [...logCards].reverse();
    setLogCards(sortData);
  }

  return (
    <>
      <div className="app-container">

        {(applicantRequestStatus === fetchStatus.LOADING ||
          jobsApplicantStatus === fetchStatus.LOADING 
        ) && <div className="app-container-loader">
            <GridLoader />
          </div>}

        <div className="px-3 pt-3">
          <div className="row">
            <div className="d-flex bd-highlight">
              <div className="bd-highlight">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">People</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <PeopleDetailCard
              isRecordValid={applicant}
              // statuses={applicantStatuses}
              selectedStatus={"New"}
              // statusVisibility={statusVisibility}
              // onStatusClicked={(e) => onStatusClicked(e)}
              // onStatusChanged={(e, value, text) => onStatusChanged(e, value, text)}


              // onChangeLocationClicked={(e) => onChangeLocationClicked(e, applicant ? applicant.id : "")}
              photoPath={applicant && applicant.PhotoPath ? applicant.PhotoPath.Data.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "") : nophoto}
              // onPhotoClicked={(e, photoData) => onPhotoClicked(e, photoData)}

              dispatchDetails={jobsApplicant}
              applicantId={recentClickedApplicant}
              // isDNR={isDNR}

              applicantPositions={applicant && _getPositionText(applicant.Position.Data)}
            />
          </div>

          <div className="col-md-9">
            <div className="default-root-container detail-container p-0">

              <div className="pt-3">
                <div className="separator">
                  <div className="line line-start"></div>
                  <input type="text" className="separator-input" placeholder="Search"
                    onChange={(e) => onLogSearch(e)}
                  />
                  <SortButton
                    SortButtonClasses="mx-2"
                    sorted={sort}
                    onSortClicked={(e) => onSort(e)}
                  />
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
              </div>

              <div className="d-flex">
                <div className="col-md-8 col-lg-8">
                  <div className="tracking-list pt-3 ps-3">
                    <LogCard statusLogs={logCards} jobsLogs={jobsApplicant} />
                  </div>
                </div>



                <div className="col-md-4 col-lg-4  end-r-3 p-3 ">
                  <div className=" a-card">
                    <div className="d-flex">
                      <span className="text-primary">Documents</span>
                      <div className="ms-auto">
                        <div className="close-btn">
                        </div>
                      </div>
                    </div>

                    <DocumentsCard documents={applicant && applicant.Documents ? applicant.Documents : ""} applicant={applicant ? applicant : ""} />
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default PeopleDetails