import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../checkers/checkbox';
// import CheckBox from '../../Controls/custom/checkers/checkbox';


function ShiftFilter(props) {
    const dispatch = useDispatch();
    //const selectedFilter = useSelector(selectSelectedFilter);

    const [open, setOpen] = useState(false);

    const shiftFilter = useSelector(props.shiftFilterSelector);

    const sfContainerRef = useRef(null);

    let _shifts = undefined;
    _shifts = [
        {
            text: "1st Shift",
            value: "1st Shift"
        },
        {
            text: "2nd Shift",
            value: "2nd Shift"
        },
        {
            text: "3rd Shift",
            value: "3rd Shift"
        }
    ];

    let shiftsList;
    let isShiftsSelected = (shiftFilter.value && Array.isArray(shiftFilter.value) && shiftFilter.value.length > 0);
    if (_shifts && Array.isArray(_shifts) && _shifts.length > 0) {
        shiftsList = _shifts.map((shift, index) => (
            <li key={shift.value} className="filter-list-item"
                onClick={(e) => props.onShiftChanged && props.onShiftChanged(e, shift.value, _shifts)}>
                <CheckBox
                    id={`ckbShift-${shift.value}`}
                    size="Small"
                    checked={isShiftsSelected ? shiftFilter.value.includes(shift.value) : false} />
                <span className={`ms-3 ${isShiftsSelected && shiftFilter.value.includes(shift.value) ? "filter-selected" : ""}`}>{shift.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && sfContainerRef && sfContainerRef.current) {
            sfContainerRef.current.focus();
        }
    });

    // const onShiftChanged = async (e, value) => {
    //     await dispatch(updateShiftFilter({
    //         shifts: _shifts,
    //         value: value
    //     }));
    // };

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Shift</span> :
                <span className="filter-value">{shiftFilter.value ? shiftFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={sfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {shiftsList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default ShiftFilter;