import { updateEmploymentTypeFilter, updatePositionFilter, updateShiftFilter } from "../../../component/Controls/filters/reducerSlices/filterSilce";
import store from "../../../store";


export const onEmploymentTypeChanged = async (e, value, employmentTypes) => {
    await store.dispatch(updateEmploymentTypeFilter({
        employmentTypes: employmentTypes,
        value: value
    }));
};

export const onPositionChanged = async (e, value, positions) => {
    await store.dispatch(updatePositionFilter({
        positions: positions,
        value: value
    }));
};

export const onShiftChanged = async (e, value, shifts) => {
    await store.dispatch(updateShiftFilter({
        shifts: shifts,
        value: value
    }));
};