import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './assets/css/custom-new.css';
import './assets/css/custom.css';
import './App.css';
import LayoutRoute from './routes/layoutRoute';
import Login from './features/accounts/screens/Login';
import Dashboard from './features/dashboard/screen/dashboard';
import JobRequestModalPopUp from './component/Controls/modals/jobRequestModalPopUp';
import Dashboard_BK from './features/dashboard/screen/dashboard_bk';
import People from './features/people/screens/people';
import PeopleDetails from './features/people/screens/PeopleDetails';
import JobOrder from './features/JobOrder/screens/jobOrder';



const App = () => {
  return (
    <>
      <Router>
        <JobRequestModalPopUp />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <LayoutRoute exact path="/dashboard" component={Dashboard} />
          <LayoutRoute exact path="/dashboard_bk" component={Dashboard_BK} />
          <LayoutRoute exact path="/people" component={People} />
          <LayoutRoute exact path="/people/details" component={PeopleDetails} />
          <LayoutRoute exact path="/jobOrder" component={JobOrder} />
        </Switch>
      </Router>
    </>
  )
}

export default App;