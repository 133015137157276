import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../checkers/checkbox';


function EmploymentTypeFilter(props) {
    const dispatch = useDispatch();
    //const selectedFilter = useSelector(selectSelectedFilter);

    const [open, setOpen] = useState(false);

    const employmentTypeFilter = useSelector(props.employmentTypeFilterSelector);

    const etfContainerRef = useRef(null);

    let _employmentTypes = undefined;
    _employmentTypes = [
        {
            text: "Full Time",
            value: "Full Time"
        },
        {
            text: "Part Time",
            value: "Part Time"
        }
    ];

    let employmentTypesList;
    let isEmploymentTypesSelected = (employmentTypeFilter.value && Array.isArray(employmentTypeFilter.value) && employmentTypeFilter.value.length > 0);
    if (_employmentTypes && Array.isArray(_employmentTypes) && _employmentTypes.length > 0) {
        employmentTypesList = _employmentTypes.map((employmentType, index) => (
            <li key={employmentType.value} className="filter-list-item"
                onClick={(e) => props.onEmploymentTypeChanged && props.onEmploymentTypeChanged(e, employmentType.value, _employmentTypes)}>
                <CheckBox
                    id={`ckbEmploymentType-${employmentType.value}`}
                    size="Small"
                    checked={isEmploymentTypesSelected ? employmentTypeFilter.value.includes(employmentType.value) : false} />
                <span className={`ms-3 ${isEmploymentTypesSelected && employmentTypeFilter.value.includes(employmentType.value) ? "filter-selected" : ""}`}>{employmentType.text}</span>

            </li>
        ));
    }

    useEffect(() => {
        if (open && etfContainerRef && etfContainerRef.current) {
            etfContainerRef.current.focus();
        }
    });

    // const onEmploymentTypeChanged = async (e, value) => {
    //     await dispatch(updateEmploymentTypeFilter({
    //         employmentTypes: _employmentTypes,
    //         value: value
    //     }));
    // };

    const onSetSelectedFilter = (e) => {
        setOpen(true);
    };

    const onBlur = (e) => {
        setOpen(false);
    };

    return (
        <div className="filter">
            <div className={`filter-title ${open ? "filter-value-selected" : ""}`}
                onClick={(e) => onSetSelectedFilter(e)}>
                <span>Employment Type</span> :
                <span className="filter-value">{employmentTypeFilter.value ? employmentTypeFilter.text : "All"}
                    <i className="fa fa-angle-down ms-2" aria-hidden="true"></i></span>
            </div>
            {open ? <div
                className="filter-control-container"
                ref={etfContainerRef}
                tabIndex={-1}
                onBlur={(e) => onBlur(e)}>
                <ul className="filter-list">
                    {employmentTypesList}
                </ul>
            </div> : <></>}
        </div>
    );
}

export default EmploymentTypeFilter;