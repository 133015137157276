export const positionsData = [
    {
        text: "General Labor",
        value: "02561F56-BA3B-4764-BAF5-334B23A7E660"
    },
    {
        text: "Production",
        value: "0708116C-22D8-4015-A41D-6D0FA51ED909"
    },
    {
        text: "Manufacturing",
        value: "075E50E2-032E-4DE8-97B8-38A4430197CB"
    },
    {
        text: "Shipping & Receiving",
        value: "0879665A-6CED-4CCD-A2C1-F48DCEF184C1"
    },
    {
        text: "General Office",
        value: "1BBB4128-0FBE-4F90-9DF0-59222DD5A135"
    },
    {
        text: "Machine Operator",
        value: "195932CF-300F-4BE7-AAB5-21D71512C742"
    },
    {
        text: "Accounting",
        value: "1D317E9D-696C-43F3-B528-1A95CA5FBCC0"
    },
    {
        text: "sanitization",
        value: "1D317E9D-696C-43F3-B528-1A95CA5FBCC1"
    },
    {
        text: "Mechanic / Technician",
        value: "1D317E9D-696C-43F3-B528-1A95CA5FBCC2"
    }
];

export const positionsDataSP = [
    {
        text: "Trabajo general",
        value: "02561F56-BA3B-4764-BAF5-334B23A7E660"
    },
    {
        text: "Producción",
        value: "0708116C-22D8-4015-A41D-6D0FA51ED909"
    },
    {
        text: "Fabricación",
        value: "075E50E2-032E-4DE8-97B8-38A4430197CB"
    },
    {
        text: "Recepción del envío",
        value: "0879665A-6CED-4CCD-A2C1-F48DCEF184C1"
    },
    {
        text: "Oficina General",
        value: "1BBB4128-0FBE-4F90-9DF0-59222DD5A135"
    },
    {
        text: "Operador de maquina",
        value: "195932CF-300F-4BE7-AAB5-21D71512C742"
    },
    {
        text: "Contabilidad",
        value: "1D317E9D-696C-43F3-B528-1A95CA5FBCC0"
    },
    {
        text: "higienización",
        value: "1D317E9D-696C-43F3-B528-1A95CA5FBCC1"
    },
    {
        text: "Mecánico / técnico",
        value: "1D317E9D-696C-43F3-B528-1A95CA5FBCC2"
    }
];

export const shiftsData = [
    {
        text: "1st Shift",
        value: "1st Shift"
    },
    {
        text: "2nd Shift",
        value: "2nd Shift"
    },
    {
        text: "3rd Shift",
        value: "3rd Shift"
    }
];

export const employmentTypesData = [
    {
        text: "Full Time",
        value: "Full Time"
    },
    {
        text: "Part Time",
        value: "Part Time"
    }
];

export const employmentTypesDataSP = [
    {
        text: "Tiempo completo",
        value: "Tiempo completo"
    },
    {
        text: "Tiempo parcial",
        value: "Tiempo parcial"
    }
];

export const forkOpeTypeData = [
    {
        text: "Cherry Picker",
        value: "1"
    },
    {
        text: "Stand Up Reach",
        value: "2"
    },
    {
        text: "Sit Down",
        value: "3"
    },
    {
        text: "Clamp",
        value: "4"
    },
    {
        text: "Slip Sheet",
        value: "5"
    },
    {
        text: "Electric",
        value: "6"
    },
    {
        text: "Pallet Jack",
        value: "7"
    }
]

export const secutrityTypeData = [
    {
        text: "Armed",
        value: "1"
    },
    {
        text: "Unarmed",
        value: "2"
    }
]

export const compKnowTypeData = [
    {
        text: "Basic Knowledge / Basico",
        value: "1"
    },
    {
        text: "Intermediate / Intermedico",
        value: "2"
    },
    {
        text: "Advanced / Avancado",
        value: "3"
    },
    {
        text: "Microsoft Word",
        value: "4"
    },
    {
        text: "Microsoft Excel",
        value: "5"
    },
    {
        text: "Microsoft Power Point",
        value: "6"
    }
]
export const LanguageData = [
    {
        text: "English",
        value: "English"
    },
    {
        text: "Spanish",
        value: "Spanish"
    },
    {
        text: "Manderin",
        value: "Manderin"
    },
    {
        text: "Cantenise",
        value: "Cantenise"
    },
    {
        text: "Other",
        value: "Other"
    },
]