import React, { useEffect, useState } from 'react';
import { updateLayout } from '../../../component/layouts/reducerSlices/layoutSlice';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeCard from './components/welcomeCard';
import PeopleCardJob from '../../../component/Card/PeopleCardJob';
import { _getDateText, toLocalDateOnly } from '../../../common/common';
import JobTimelineCard from '../../../component/Card/JobTimelineCard';
import { getAllJobs, selectAllJobs, selectCurrentPage, selectStatus, selectTotalItems, updateStatus } from '../reducerSlices/jobSlice';
import { fetchStatus } from '../../../api/client';
import Collection from '../../../component/Controls/paginators/collection';
import { isObjectArray } from '../../../utilities/utilityFunctions';
import { positionsData } from '../../people/screens/masterData';

function JobOrder() {

  const dispatch = useDispatch();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [jobOrderLoaded, setJobOrderLoaded] = useState(false);


  const totalPageItems = useSelector(selectTotalItems);
  const jobs = useSelector(selectAllJobs);
  const jobsRequestStatus = useSelector(selectStatus);

  const currentPage = useSelector(selectCurrentPage);


  useEffect(() => {
    if (!pageLoaded) {
      dispatch(updateLayout({
        page: 2,
        title: "Jobs"
      }));
      setPageLoaded(true);
    }
  });

  const _getPositionText = (id) => {
    if (positionsData) {
      let position = positionsData.find(p => p.value === id);
      if (position && position.text) {
        return position.text;
      }
    }
    return "N/A";
  }


  const _loadJobs = async (page, offset, limit, whereClause = undefined, sort) => {
    await dispatch(getAllJobs({
      page: page,
      model: {
        job: "test",
        serviceType: "TENANTADMIN",
        // operation: "GETJOBS",
        operation: "GETJOBSPOSITIONFORCLIENTPORTAL",
        payload: {
          id: "",
          data: JSON.stringify({
            offset: offset,
            limit: limit,
            where: whereClause,
            sort: sort
          })
        }
      }
    }));
  }

  const whereClause = {
    location: {
      value: [],
      text: "All"
    },
    client: {
      value: ['25b82228-bd09-40a3-895b-24906cf65e8a'],
      text: "All"

    },
    position: {
      value: [],
      text: "All"

    },
    jobDate: {
      equal: ""
    },
    jobStatus: {
      value: [],
      text: "All"
    },
    title: ""
  };

  const sort = {
    by: 1,
    ascending: false
  }

  useEffect(() => {
    if (jobsRequestStatus === fetchStatus.IDLE || jobOrderLoaded === false) {
      _loadJobs(0, 0, 10, whereClause, sort);
    }
    setJobOrderLoaded(true);
  }, [jobsRequestStatus, dispatch]);


  const [jobRecords, setJobRecords] = useState(new Collection());

  let jobCards;
  let _jobRecords = new Collection();

  useEffect(() => {
    if (jobsRequestStatus === fetchStatus.SUCCEEDED) {
      let _records = jobs.Items;


      if (jobs.Items && jobs.Items.length > 0) {
        for (let i = 0; i < jobs.Items.length; i++) {
          let item = jobs.Items[i];
          jobCards = item.records.map((job, index) => (
            console.log('job ----->', job),
            <JobTimelineCard
              key={job.Id}

              startDate={_getDateText(job.StartDate)}
              startTime={job.StartTime}
              title={job.Title}
              address={job.Address}
              reportTo={job.ReportTo}
            // jobCardClasses="mb-2"
            // name={job.Title}
            requestDate={_getDateText(job.RequestDate)}
            dressCode={job.DressCode}
            position={_getPositionText(job.Position)}
            jobType={job.JobType}
            payRates={job.PayRates}
            // endDate={_getDateText(job.EndDate)}
            // endTime={job.EndTime}
            // status={job.Status}
            // filled={job.Filled}
            // total={job.Total}
            // reportTo={job.ReportTo}
            // numberOfActivePeople={job.NumberOfActivePeople}
            // onClick={() => onClickJob(job.id)}
            />
          ));
          _jobRecords.Add(item.key, jobCards);
          // setJobData(jobCards);
        }

        setJobRecords(_jobRecords);
        dispatch(updateStatus());
      }
      dispatch(updateStatus());
    }
  }, [jobsRequestStatus, currentPage]);




  const applicant = {
    ImageData: '',
    FirstName: 'Jonh',
    LastName: 'Smith',
    DateOfBirth: new Date('1992-08-20'),
    Gender: "Male"
  }


  return (
    <div className="app-container">.
      <div className='px-3'>
        <WelcomeCard />
      </div>

      <div className="row p-2">
        <div className="col-md-8">
          <div className='jobs-container'>
            <div className="jobs-container-header">
              <div className='d-flex'>
                <div class="d-flex flex-column"> <span class="">Jobs</span> <span class="text-secondary">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </span> </div>
                <div class="d-flex flex-column ms-auto"> <span class="">Card</span>
                </div>
              </div>
            </div>
            <div className="jobs-container-body">


              {

                jobRecords.Get(currentPage)
                  && isObjectArray(jobRecords.Get(currentPage).records)
                  && jobRecords.Get(currentPage).records.length > 0 ?
                  jobRecords.Get(currentPage).records :
                  <div className="empty-page">
                    <span>No records found</span>
                    <span>Please refresh search filters</span>
                  </div>
              }

            </div>
          </div>

        </div>
        <div className="col-md-4">
          <div className="default-container p-3">
            <p className='title'> D'Amore, Turcotte and Corp</p>
            <span className='sub-title'>Account Manager (Light)</span>

            {
              [1, 2, 3, 4].map(() => {
                return <PeopleCardJob
                  profilePhoto={applicant.ImageData}
                  fullName={applicant.FirstName + " " + applicant.LastName}
                  noOfJobs={7}
                  dateOfBirth={toLocalDateOnly(applicant.DateOfBirth)}
                  position={"Product Manager, Accounting"}
                  workingSince={toLocalDateOnly(new Date())}
                  Experience={"1 Year"}
                  status={applicant.Gender === 'Male' ? 'Repeate' : 'New'}
                  gender={"Male"}

                // onClick={(e) => onClickApplicant(e, applicant.id)}
                />
              })
            }

          </div>



        </div>
      </div>
    </div>
  )
}

export default JobOrder;