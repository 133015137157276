import React from 'react';
import womanSketchImg from './../../../assets/images/clipart/woman-sketch.svg';
import boyFaceImg from './../../../assets/images/clipart/boy-face.svg';

import i9Img from '../../../assets/images/clipart/i9.svg';
import fileImg from './../../../assets/images/clipart/file.svg';
import genderImg from './../../../assets/images/clipart/gender.svg';
import termsImg from './../../../assets/images/clipart/terms.svg';
import folderImg from './../../../assets/images/clipart/folder.svg';
import pdfImg from '../../../assets/images/clipart/pdf.svg';

function Dashboard_BK() {
  return (
    <div className='d-flex justify-content-md-center align-items-center vh-100'>
      <div className='page-container'>
        <div className='row'>
          <div className='col-md-9'>
            <div className='heading-card'>
              <div className='d-flex'>
                <img src={womanSketchImg} alt='not found' />
                <div className=''>
                  <h2 className='title'>Good Morning, Allen</h2>
                  <p className='sub-title'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>

                  <div className='d-flex'>
                    <div class="d-flex flex-column"> <span class="">45</span> <span class="text-secondary">Total Job</span> </div>
                    <div class="d-flex flex-column ms-5"> <span class="">$2,000.00</span> <span class="text-secondary">Total Revenue</span> </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-3'>
              <div className='timeline-card'>
                <div className='row p-3'>
                  <div className='col-md-5'>
                    <p className='title'> D'Amore, Turcotte and Corp</p>
                    <span className='sub-title'>3064 Schinner, Village Suite 621.</span>
                  </div>
                  <div className='col-md-5'>
                    <p className='title'>Account Manager (Light)</p>
                    <span className='sub-title'>Start Date - 11/26/2022, 22:48:33</span>
                  </div>
                  <div className='col-md-2'>
                    Check In
                  </div>
                </div>
                <div className='row px-3 py-1 bg-light-gray-200'>
                  <div className='col'>
                    <span className='text-secondary'> <i className='fa fa-user me-2' />Report To: Adam Smith</span>
                  </div>
                  <div className='col'>
                    <span className='text-secondary'> <i className='fa fa-location-dot me-2' />Arrived: 11/25/2022, 22:48:33</span>
                  </div>
                  <div className='col'>
                    <span className='text-secondary'> <i className='fa fa-shirt me-2' />Dress Code: DC3</span>
                  </div>
                  <div className='col-md-2'>
                    <span className='text-secondary'> <i className='fa fa-sack-dollar me-2' />$57.90 | 8 hours</span>
                  </div>
                </div>
                <div className='row p-3'>
                  <div className='col-md-12'>
                    <span className='note'>
                      Note : Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-3'>
              <div className='timeline-card'>
                <div className='row p-3'>
                  <div className='col-md-5'>
                    <p className='title'> D'Amore, Turcotte and Corp</p>
                    <span className='sub-title'>3064 Schinner, Village Suite 621.</span>
                  </div>
                  <div className='col-md-5'>
                    <p className='title'>Account Manager (Light)</p>
                    <span className='sub-title'>Start Date - 11/26/2022, 22:48:33</span>
                  </div>
                  <div className='col-md-2'>
                    Check In
                  </div>
                </div>
                <div className='px-3 py-1'>
                  <span class="badge rounded-pill badge-blue me-3"><i className='fa fa-user me-2' />Report To: Adam Smith</span>
                  <span class="badge rounded-pill badge-yellow me-3"><i className='fa fa-location-dot me-2' />Arrived: 11/25/2022, 22:48:33</span>
                  <span class="badge rounded-pill badge-purple me-3"><i className='fa fa-shirt me-2' />Dress Code: DC3</span>
                  <span class="badge rounded-pill badge-indigo-blue"><i className='fa fa-sack-dollar me-2' />$50.00 | 6 hours</span>
                </div>

                <div className='h-line mt-3' />
                <div className='row p-3'>
                  <div className='col-md-12'>
                    <span className='note'>
                      Note : Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                    </span>
                  </div>
                </div>
              </div>
            </div>


          </div>





          <div className='col-md-3'>
            <div className='profile-card'>
              <div className='d-flex'>
                <img src={boyFaceImg} className='profile-card-avatar' alt='profile not' />
                <div className='ms-3  '>
                  <h3 className='profile-card-fullname '>Allen Yarbrough</h3>
                  <h6 className='profile-card-jobtitle'>Product Manager</h6>
                  <div className='d-flex'>
                    <div className='profile-status'>Available</div>
                    <button
                      type="button"
                      className='c-btn dark-btn ms-3'>
                      Edit Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className='document-wrapper mt-3'>

              <div className="d-flex">
                <span className="">Documents</span>
                <div className="ms-auto">
                  <div className="close-btn">
                    {/* <i className="fa fa-cloud-download" aria-hidden="true"></i> */}
                  </div>
                </div>
              </div>

              <div class="accordion" id="accordionPeopleAttachment">

                <div class="flyout-list-group-item-card-container mt-3">
                  <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    <img src={i9Img} className="icon-img" alt='i9 not found' />
                    <span className="bold-text ps-2">
                      I9
                    </span>
                    <span className="ms-auto light-text">
                      0 Items
                    </span>
                  </div>
                  <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                    <div className="flyout-list-group-item-card-footer">
                      <div className="flyout-list-group-item-card system">
                        <div className='sub-icon'>
                          <img src={pdfImg} className="icon-img" alt='pdf not found' />
                          <p className='file-size-text'>0kb</p>
                        </div>
                        <span className="ps-2">
                          <div className='file-name'>Pre-Filled Template</div>
                          <div className='file-by'>System Generated</div>
                        </span>
                        <span className="ms-auto d-flex">
                          <i className="fa fa-download icon-btn" aria-hidden="true" onClick="" data-tip='Download'></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flyout-list-group-item-card-container mt-3">
                  <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                    <img src={fileImg} className="icon-img" alt='w4 not found' />
                    <span className="bold-text ps-2">
                      w4
                    </span>
                    <span className="ms-auto light-text">
                      0 Items
                    </span>
                  </div>
                  <div id="collapse2" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                    <div className="flyout-list-group-item-card-footer">
                      <div className="flyout-list-group-item-card system">
                        <div className='sub-icon'>
                          <img src={pdfImg} className="icon-img" alt='pdf not found' />
                          <p className='file-size-text'>0kb</p>
                        </div>
                        <span className="ps-2">
                          <div className='file-name'>Pre-Filled Template</div>
                          <div className='file-by'>System Generated</div>
                        </span>
                        <span className="ms-auto d-flex">
                          <i className="fa fa-download icon-btn" aria-hidden="true" onClick="" data-tip='Download'></i>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="flyout-list-group-item-card-container mt-3">
                  <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                    <img src={genderImg} className="icon-img" alt='i9 not found' />
                    <span className="bold-text ps-2">
                      Sexual Harassment
                    </span>
                    <span className="ms-auto light-text">
                      0 Items
                    </span>
                  </div>
                  <div id="collapse3" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                    <div className="flyout-list-group-item-card-footer">
                      <div className="flyout-list-group-item-card system">
                        <div className='sub-icon'>
                          <img src={pdfImg} className="icon-img" alt='pdf not found' />
                          <p className='file-size-text'>0kb</p>
                        </div>
                        <span className="ps-2">
                          <div className='file-name'>Pre-Filled Template</div>
                          <div className='file-by'>System Generated</div>
                        </span>
                        <span className="ms-auto d-flex">
                          <i className="fa fa-download icon-btn" aria-hidden="true" onClick="" data-tip='Download'></i>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="flyout-list-group-item-card-container mt-3">
                  <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                    <img src={termsImg} className="icon-img" alt='i9 not found' />
                    <span className="bold-text ps-2">
                      Terms
                    </span>
                    <span className="ms-auto light-text">
                      0 Items
                    </span>
                  </div>
                  <div id="collapse4" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                    <div className="flyout-list-group-item-card-footer">
                      <div className="flyout-list-group-item-card system">
                        <div className='sub-icon'>
                          <img src={pdfImg} className="icon-img" alt='pdf not found' />
                          <p className='file-size-text'>0kb</p>
                        </div>
                        <span className="ps-2">
                          <div className='file-name'>Pre-Filled Template</div>
                          <div className='file-by'>System Generated</div>
                        </span>
                        <span className="ms-auto d-flex">
                          <i className="fa fa-download icon-btn" aria-hidden="true" onClick="" data-tip='Download'></i>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="flyout-list-group-item-card-container mt-3">
                  <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                    <img src={folderImg} className="icon-img" alt='i9 not found' />
                    <span className="bold-text ps-2">
                      Others
                    </span>
                    <span className="ms-auto light-text">
                      0 Items
                    </span>
                  </div>
                  <div id="collapse5" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                    <div className="flyout-list-group-item-card-footer">
                      <div className="flyout-list-group-item-card system">
                        <div className='sub-icon'>
                          <img src={pdfImg} className="icon-img" alt='pdf not found' />
                          <p className='file-size-text'>0kb</p>
                        </div>
                        <span className="ps-2">
                          <div className='file-name'>Pre-Filled Template</div>
                          <div className='file-by'>System Generated</div>
                        </span>
                        <span className="ms-auto d-flex">
                          <i className="fa fa-download icon-btn" aria-hidden="true" onClick="" data-tip='Download'></i>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="flyout-list-group-item-card-container mt-3">
                  <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                    <img src={i9Img} className="icon-img" alt='i9 not found' />
                    <span className="bold-text ps-2">
                      All
                    </span>
                    <span className="ms-auto light-text">
                      0 Items
                    </span>
                  </div>
                  <div id="collapse6" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                    <div className="flyout-list-group-item-card-footer">
                      <div className="flyout-list-group-item-card system">
                        <div className='sub-icon'>
                          <img src={pdfImg} className="icon-img" alt='pdf not found' />
                          <p className='file-size-text'>0kb</p>
                        </div>
                        <span className="ps-2">
                          <div className='file-name'>Pre-Filled Template</div>
                          <div className='file-by'>System Generated</div>
                        </span>
                        <span className="ms-auto d-flex">
                          <i className="fa fa-download icon-btn" aria-hidden="true" onClick="" data-tip='Download'></i>
                        </span>
                      </div>

                    </div>
                  </div>
                </div>




              </div>
            </div>
          </div>
        </div>

        {/* <div className='row'>
          <div className='col-12'>

            
<div class="container table-responsive py-5"> 
<table class="table table-bordered table-hover">
  <thead class="thead-dark">
    <tr>
          <th>Employee Name</th>
          <th>Mobile No</th>
          <th>Job Tiitle</th>
          <th>Position</th>
          <th>Location</th>
          <th>Dress Code</th>
          <th>Shift</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Thelma Dean</td>
      <td>(123)456-0000</td>
      <td>MO-1</td>
      <td>Machine Operator</td>
      <td>Buena Park</td>
      <td>Closed steel Toe Shoes, Jeans, T-Shirt</td>
      <td>1st</td>
    </tr>
    <tr>
      <td>Victoria  Cordova</td>
       <td>(123)456-0000</td>
      <td>Gl-1</td>
      <td>General Labor</td>
      <td>Bell</td>
      <td>jeans, closed shoes</td>
       <td>2nd</td>
    </tr>
     <tr>
      <td>Jeremiah Saunders</td>
        <td>(123)456-0000</td>
      <td>GLO</td>
      <td>General Office</td>
      <td>Buena Park</td>
       <td>Dress Casual</td>
        <td>3rd</td>
    </tr>
    
  </tbody>
</table>
</div>

          </div>
        </div> */}

      </div>
    </div>
  )
}

export default Dashboard_BK;