import {
    createSlice
}
    from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: 0,
    id: "",
    name: "",
    defaultFilter: false,
    where: {
        location: {
            value: [],
            text: "All"
        },
        preferredClient: {
            value: [],
            text: "All"
        },
        shift: {
            value: [],
            text: "All"
        },
        workStatus: {
            value: [],
            text: "All"
        },
        position: {
            value: [],
            text: "All"
        },
        employmentType: {
            value: [],
            text: "All"
        },
        ethinicity: {
            value: [],
            text: "All"
        },
        maritalStatus: {
            value: [],
            text: "All"
        },
        gender: {
            value: [],
            text: "All"
        },
        appliedDate: {
            equal: "",
            between: {
                start: "",
                end: ""
            }
        },
        language: {
            value: [],
            text: "All"
        },
        flexibility: "-1",
        calledOn: false,
        availableForOT: "-1",
        willingToComute: "-1",
        fastPacedEnv: "-1",
        standingLongPeriod: "-1",
        workInHotTemp: "-1",
        repetitiveMov: "-1",
        workinColdTemp: "-1",
        heavyLifting: "-1",
        wentOverSafetyProtocol: "-1",
        wentOverUniformProtocol: "-1",
        noShowPolicy: "-1",
        timeClockPolicy: "-1",
        weekPayPolicy: "-1",
        stillToeBoots: "-1",
        slipResistantBoots: "-1",
        workWeekends: "-1",
        mandateLunch: "-1",
        oneDayAssignment: "-1",
        ReadEnglish: "-1",
        WriteEnglish: "-1",
        WriteSpanish: "-1",
        ReadSpanish: "-1",
        BasicMathSkills: "-1",
        CovidVaccinated: "-1",
        generalLaber: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        shippingReceiving: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        packagingLabeling: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        industrialSewing: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        rfScanning: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        assemblyLine: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        foodProduction: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        machineOperator: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        supervisorLead: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        maintainanceMechanic: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        electrician: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        sanitation: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        qualityAssurance: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        forkLiftOperator: {
            havingExperience: "-1",
            type: "",
            experience: {
                operation: "",
                value: ""
            },
            havingCertificate: "-1"
        },
        security: {
            havingExperience: "-1",
            type: "",
            experience: {
                operation: "",
                value: ""
            }
        },
        computer: {
            havingExperience: "-1",
            type: "",
            experience: {
                operation: "",
                value: ""
            }
        },
        apparelMachineOperator: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        apparelLoader: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        screenPrinting: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
        ticketingLabeling: {
            havingExperience: "-1",
            experience: {
                operation: "",
                value: ""
            }
        },
    },
    refreshData: false
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        updateRefreshData: (state, action) => {
            state.refreshData = action.payload;
        },
        updateFilter: (state, action) => {
            let { id, name, whereClause, defaultFilter } = action.payload;
            state.id = id;
            state.name = name;
            state.defaultFilter = defaultFilter;
            whereClause = whereClause ? whereClause : {
                location: {
                    value: [],
                    text: "All"
                },
                preferredClient : {
                    value: [],
                    text: "All"
                },
                shift: {
                    value: [],
                    text: "All"
                },
                workStatus: {
                    value: [],
                    text: "All"
                },
                position: {
                    value: [],
                    text: "All"
                },
                employmentType: {
                    value: [],
                    text: "All"
                },
                ethinicity: {
                    value: [],
                    text: "All"
                },
                maritalStatus: {
                    value: [],
                    text: "All"
                },
                gender: {
                    value: [],
                    text: "All"
                },
                appliedDate: {
                    equal: "",
                    between: {
                        start: "",
                        end: ""
                    }
                },
                language: {
                    value: [],
                    text: "All"
                },
                flexibility: "-1",
                calledOn: false,
                availableForOT: "-1",
                willingToComute: "-1",
                fastPacedEnv: "-1",
                standingLongPeriod: "-1",
                workInHotTemp: "-1",
                repetitiveMov: "-1",
                workinColdTemp: "-1",
                heavyLifting: "-1",
                wentOverSafetyProtocol: "-1",
                wentOverUniformProtocol: "-1",
                noShowPolicy: "-1",
                timeClockPolicy: "-1",
                weekPayPolicy: "-1",
                stillToeBoots: "-1",
                slipResistantBoots: "-1",
                workWeekends: "-1",
                mandateLunch: "-1",
                oneDayAssignment: "-1",
                ReadEnglish: "-1",
                WriteEnglish: "-1",
                WriteSpanish: "-1",
                ReadSpanish: "-1",
                BasicMathSkills: "-1",
                CovidVaccinated: "-1",
                generalLaber: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                shippingReceiving: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                packagingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                industrialSewing: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                rfScanning: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                assemblyLine: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                foodProduction: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                machineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                supervisorLead: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                maintainanceMechanic: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                electrician: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                sanitation: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                qualityAssurance: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                forkLiftOperator: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    },
                    havingCertificate: "-1"
                },
                security: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                computer: {
                    havingExperience: "-1",
                    type: "",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelMachineOperator: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                apparelLoader: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                screenPrinting: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
                ticketingLabeling: {
                    havingExperience: "-1",
                    experience: {
                        operation: "",
                        value: ""
                    }
                },
            };
            state.where = Object.assign({}, whereClause);
        },
        updateSelectedFilter: (state, action) => {
            let sf = action.payload;
            if (sf === state.selectedFilter) {
                sf = -1;
            }
            state.selectedFilter = sf;
        },
        updateLocationFilter: (state, action) => {
            const { locations, value } = action.payload;
            let location = { ...state.where.location };
            let text = "All";
            if (!location.value.includes(value)) {
                location.value = [...location.value, value];
            }
            else {
                location.value = location.value.filter((val) => (val !== value));
            }
            if ((locations && Array.isArray(locations) && locations.length > 0) && location.value.length > 0) {
                text = "";
                for (let i = 0; i < locations.length; i++) {
                    let loc = locations[i];
                    if (location.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            location.text = text;
            location.text = location.value.length > 0 ? `(${location.value.length}) selected` : "All";
            state.where.location = location;
        },
        updatePreferredClientFilter: (state, action) => {
            const {preferredClients, value } = action.payload;
            let preferredClient = { ...state.where.preferredClient };
            let text = "All";
            if (!preferredClient.value.includes(value)) {
                preferredClient.value = [...preferredClient.value, value];
            }
            else {
                preferredClient.value = preferredClient.value.filter((val) => (val !== value));
            }
            if ((preferredClients && Array.isArray(preferredClients) && preferredClients.length > 0) && preferredClient.value.length > 0) {
                text = "";
                for (let i = 0; i < preferredClients.length; i++) {
                    let cli = preferredClients[i];
                    if (preferredClient.value.includes(cli.value)) {
                        text += `${cli.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            preferredClient.text = text;
            preferredClient.text = preferredClient.value.length > 0 ? `(${preferredClient.value.length}) selected` : "All";
            state.where.preferredClient = preferredClient;
        },
        

        
        updateShiftFilter: (state, action) => {
            const { shifts, value } = action.payload;
            let shift = { ...state.where.shift };
            let text = "All";
            if (!shift.value.includes(value)) {
                shift.value = [...shift.value, value];
            }
            else {
                shift.value = shift.value.filter((val) => (val !== value));
            }
            if ((shifts && Array.isArray(shifts) && shifts.length > 0) && shift.value.length > 0) {
                text = "";
                for (let i = 0; i < shifts.length; i++) {
                    let loc = shifts[i];
                    if (shift.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            shift.text = text;
            shift.text = shift.value.length > 0 ? `(${shift.value.length}) selected` : "All";
            state.where.shift = shift;
        },
        updatePositionFilter: (state, action) => {
            const { positions, value } = action.payload;
            let position = { ...state.where.position };
            let text = "All";
            if (!position.value.includes(value)) {
                position.value = [...position.value, value];
            }
            else {
                position.value = position.value.filter((val) => (val !== value));
            }
            if ((positions && Array.isArray(positions) && positions.length > 0) && position.value.length > 0) {
                text = "";
                for (let i = 0; i < positions.length; i++) {
                    let loc = positions[i];
                    if (position.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            position.text = text;
            position.text = position.value.length > 0 ? `(${position.value.length}) selected` : "All";
            state.where.position = position;
        },
        updateEmploymentTypeFilter: (state, action) => {
            const { employmentTypes, value } = action.payload;
            let employmentType = { ...state.where.employmentType };
            let text = "All";
            if (!employmentType.value.includes(value)) {
                employmentType.value = [...employmentType.value, value];
            }
            else {
                employmentType.value = employmentType.value.filter((val) => (val !== value));
            }
            if ((employmentTypes && Array.isArray(employmentTypes) && employmentTypes.length > 0) && employmentType.value.length > 0) {
                text = "";
                for (let i = 0; i < employmentTypes.length; i++) {
                    let loc = employmentTypes[i];
                    if (employmentType.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            employmentType.text = text;
            employmentType.text = employmentType.value.length > 0 ? `(${employmentType.value.length}) selected` : "All";
            state.where.employmentType = employmentType;
        },
        updateEthinicityFilter: (state, action) => {
            const { ethinicities, value } = action.payload;
            let ethinicity = { ...state.where.ethinicity };
            let text = "All";
            if (!ethinicity.value.includes(value)) {
                ethinicity.value = [...ethinicity.value, value];
            }
            else {
                ethinicity.value = ethinicity.value.filter((val) => (val !== value));
            }
            if ((ethinicities && Array.isArray(ethinicities) && ethinicities.length > 0) && ethinicity.value.length > 0) {
                text = "";
                for (let i = 0; i < ethinicities.length; i++) {
                    let loc = ethinicities[i];
                    if (ethinicity.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            ethinicity.text = text;
            ethinicity.text = ethinicity.value.length > 0 ? `(${ethinicity.value.length}) selected` : "All";
            state.where.ethinicity = ethinicity;
        },
        updateWorkStatusFilter: (state, action) => {
            const { workStatuses, value } = action.payload;
            let workStatus = { ...state.where.workStatus };
            let text = "All";
            if (!workStatus.value.includes(value)) {
                workStatus.value = [...workStatus.value, value];
            }
            else {
                workStatus.value = workStatus.value.filter((val) => (val !== value));
            }
            if ((workStatuses && Array.isArray(workStatuses) && workStatuses.length > 0) && workStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < workStatuses.length; i++) {
                    let loc = workStatuses[i];
                    if (workStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            workStatus.text = text;
            workStatus.text = workStatus.value.length > 0 ? `(${workStatus.value.length}) selected` : "All";
            state.where.workStatus = workStatus;
        },
        updateMaritalStatusFilter: (state, action) => {
            const { maritalStatuses, value } = action.payload;
            let maritalStatus = { ...state.where.maritalStatus };
            let text = "All";
            if (!maritalStatus.value.includes(value)) {
                maritalStatus.value = [...maritalStatus.value, value];
            }
            else {
                maritalStatus.value = maritalStatus.value.filter((val) => (val !== value));
            }
            if ((maritalStatuses && Array.isArray(maritalStatuses) && maritalStatuses.length > 0) && maritalStatus.value.length > 0) {
                text = "";
                for (let i = 0; i < maritalStatuses.length; i++) {
                    let loc = maritalStatuses[i];
                    if (maritalStatus.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            maritalStatus.text = text;
            maritalStatus.text = maritalStatus.value.length > 0 ? `(${maritalStatus.value.length}) selected` : "All";
            state.where.maritalStatus = maritalStatus;
        },
        updateGenderFilter: (state, action) => {
            const { genders, value } = action.payload;
            let gender = { ...state.where.gender };
            let text = "All";
            if (!gender.value.includes(value)) {
                gender.value = [...gender.value, value];
            }
            else {
                gender.value = gender.value.filter((val) => (val !== value));
            }
            if ((genders && Array.isArray(genders) && genders.length > 0) && gender.value.length > 0) {
                text = "";
                for (let i = 0; i < genders.length; i++) {
                    let loc = genders[i];
                    if (gender.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            gender.text = text;
            gender.text = gender.value.length > 0 ? `(${gender.value.length}) selected` : "All";
            state.where.gender = gender;
        },
        updateNameFilter: (state, action) => {
            state.where.name = action.payload;
        },
        updateAppliedDateFilter: (state, action) => {
            const { filterAction, equal, start, end } = action.payload;
            if (filterAction === 0) {
                state.where.appliedDate = { ...state.where.appliedDate, equal: equal, between: { ...state.where.appliedDate.between, start: "", end: "" } }
            }
            else {
                state.where.appliedDate = { ...state.where.appliedDate, equal: "", between: { ...state.where.appliedDate.between, start: start, end: end } }
            }
        },
        updateLanguageFilter: (state, action) => {
            const { languages, value } = action.payload;
            let language = { ...state.where.language };
            let text = "All";
            if (!language.value.includes(value)) {
                language.value = [...language.value, value];
            }
            else {
                language.value = language.value.filter((val) => (val !== value));
            }
            if ((languages && Array.isArray(languages) && languages.length > 0) && language.value.length > 0) {
                text = "";
                for (let i = 0; i < languages.length; i++) {
                    let loc = languages[i];
                    if (language.value.includes(loc.value)) {
                        text += `${loc.text},`
                    }
                }
                text = text.slice(0, -1);
            }
            language.text = text;
            language.text = language.value.length > 0 ? `(${language.value.length}) selected` : "All";
            state.where.language = language;
        },
        updateFlexibilityFilter: (state, action) => {
            state.where.flexibility = action.payload;
        },
        updateCalledOnFilter: (state, action) => {
            state.where.calledOn = action.payload;
        },
        updateAvailableForOTFilter: (state, action) => {
            state.where.availableForOT = action.payload;
        },
        updateReadEnglishFilter: (state, action) => {
            state.where.ReadEnglish = action.payload;
        },
        updateWriteEnglishFilter: (state, action) => {
            state.where.WriteEnglish = action.payload;
        },
        updateWriteSpanishFilter: (state, action) => {
            state.where.WriteSpanish = action.payload;
        },
        updateReadSpanishFilter: (state, action) => {
            state.where.ReadSpanish = action.payload;
        },
        updateBasicMathSkillsFilter: (state, action) => {
            state.where.BasicMathSkills = action.payload;
        },
        updateCovidVaccinatedFilter: (state, action) => {
            state.where.CovidVaccinated = action.payload;
        },



        updateWillingToComuteFilter: (state, action) => {
            state.where.willingToComute = action.payload;
        },
        updateFastPacedEnvFilter: (state, action) => {
            state.where.fastPacedEnv = action.payload;
        },
        updateStandingLongPeriodFilter: (state, action) => {
            state.where.standingLongPeriod = action.payload;
        },
        updateWorkInHotTempFilter: (state, action) => {
            state.where.workInHotTemp = action.payload;
        },
        updateRepetitiveMovFilter: (state, action) => {
            state.where.repetitiveMov = action.payload;
        },
        updateWorkinColdTempFilter: (state, action) => {
            state.where.workinColdTemp = action.payload;
        },
        updateHeavyLiftingFilter: (state, action) => {
            state.where.heavyLifting = action.payload;
        },
        updateWentOverSafetyProtocolFilter: (state, action) => {
            state.where.wentOverSafetyProtocol = action.payload;
        },
        updateWentOverUniformProtocolFilter: (state, action) => {
            state.where.wentOverUniformProtocol = action.payload;
        },
        updateNoShowPolicyFilter: (state, action) => {
            state.where.noShowPolicy = action.payload;
        },
        updateTimeClockPolicyFilter: (state, action) => {
            state.where.timeClockPolicy = action.payload;
        },
        updateWeekPayPolicyFilter: (state, action) => {
            state.where.weekPayPolicy = action.payload;
        },
        updateStillToeBootsFilter: (state, action) => {
            state.where.stillToeBoots = action.payload;
        },
        updateSlipResistantBootsFilter: (state, action) => {
            state.where.slipResistantBoots = action.payload;
        },
        updateWorkWeekendsFilter: (state, action) => {
            state.where.workWeekends = action.payload;
        },
        updateMandateLunchFilter: (state, action) => {
            state.where.mandateLunch = action.payload;
        },
        updateOneDayAssignmentFilter: (state, action) => {
            state.where.oneDayAssignment = action.payload;
        },
        updateGeneralLaberFilter: (state, action) => {
            state.where.generalLaber = action.payload;
        },
        updateShippingReceivingFilter: (state, action) => {
            state.where.shippingReceiving = action.payload;
        },
        updatePackagingLabelingFilter: (state, action) => {
            state.where.packagingLabeling = action.payload;
        },
        updateIndustrialSewingFilter: (state, action) => {
            state.where.industrialSewing = action.payload;
        },
        updateRfScanningFilter: (state, action) => {
            state.where.rfScanning = action.payload;
        },
        updateAssemblyLineFilter: (state, action) => {
            state.where.assemblyLine = action.payload;
        },
        updateFoodProductionFilter: (state, action) => {
            state.where.foodProduction = action.payload;
        },
        updateMachineOperatorFilter: (state, action) => {
            state.where.machineOperator = action.payload;
        },
        updateSupervisorLeadFilter: (state, action) => {
            state.where.supervisorLead = action.payload;
        },
        updateMaintainanceMechanicFilter: (state, action) => {
            state.where.maintainanceMechanic = action.payload;
        },
        updateElectricianFilter: (state, action) => {
            state.where.electrician = action.payload;
        },
        updateSanitationFilter: (state, action) => {
            state.where.sanitation = action.payload;
        },
        updateQualityAssuranceFilter: (state, action) => {
            state.where.qualityAssurance = action.payload;
        },
        updateForkLiftOperatorFilter: (state, action) => {
            state.where.forkLiftOperator.havingExperience = action.payload.havingExperience;
            state.where.forkLiftOperator.havingCertificate = action.payload.havingCertificate;
            state.where.forkLiftOperator.type = action.payload.type;
            state.where.forkLiftOperator.experience.operation = action.payload.experience.operation;
            state.where.forkLiftOperator.experience.value = action.payload.experience.value;
        },
        updateSecurityFilter: (state, action) => {
            state.where.security.havingExperience = action.payload.havingExperience;
            state.where.security.type = action.payload.type;
            state.where.security.experience.operation = action.payload.experience.operation;
            state.where.security.experience.value = action.payload.experience.value;
        },
        updateComputerFilter: (state, action) => {
            state.where.computer.havingExperience = action.payload.havingExperience;
            state.where.computer.type = action.payload.type;
            state.where.computer.experience.operation = action.payload.experience.operation;
            state.where.computer.experience.value = action.payload.experience.value;
        },
        updateApparelMachineOperatorFilter: (state, action) => {
            state.where.apparelMachineOperator = action.payload;
        },
        updateApparelLoaderFilter: (state, action) => {
            state.where.apparelLoader = action.payload;
        },
        updateScreenPrintingFilter: (state, action) => {
            state.where.screenPrinting = action.payload;
        },
        updateTicketingLabelingFilter: (state, action) => {
            state.where.ticketingLabeling = action.payload;
        },
    }
});

export const { updateRefreshData, updateFilter, updateSelectedFilter, updateLocationFilter,  updatePreferredClientFilter, updateShiftFilter,
    updateWorkStatusFilter, updatePositionFilter, updateEmploymentTypeFilter,
    updateEthinicityFilter, updateMaritalStatusFilter, updateGenderFilter,
    updateAppliedDateFilter, updateLanguageFilter, updateNameFilter, updateCalledOnFilter, updateFlexibilityFilter,
    updateAvailableForOTFilter, updateWillingToComuteFilter, updateFastPacedEnvFilter,
    updateStandingLongPeriodFilter, updateWorkInHotTempFilter, updateRepetitiveMovFilter,
    updateWorkinColdTempFilter, updateHeavyLiftingFilter, updateWentOverSafetyProtocolFilter,
    updateWentOverUniformProtocolFilter, updateNoShowPolicyFilter, updateTimeClockPolicyFilter,
    updateWeekPayPolicyFilter, updateStillToeBootsFilter, updateSlipResistantBootsFilter,
    updateWorkWeekendsFilter, updateMandateLunchFilter, updateReadEnglishFilter, updateWriteEnglishFilter, updateWriteSpanishFilter, updateReadSpanishFilter, updateBasicMathSkillsFilter, updateCovidVaccinatedFilter, updateOneDayAssignmentFilter, updateGeneralLaberFilter,
    updateShippingReceivingFilter, updatePackagingLabelingFilter,
    updateIndustrialSewingFilter, updateRfScanningFilter,
    updateAssemblyLineFilter, updateFoodProductionFilter, updateMachineOperatorFilter,
    updateSupervisorLeadFilter, updateMaintainanceMechanicFilter,
    updateElectricianFilter, updateSanitationFilter,
    updateQualityAssuranceFilter, updateForkLiftOperatorFilter,
    updateSecurityFilter, updateComputerFilter,
    updateApparelMachineOperatorFilter, updateApparelLoaderFilter,
    updateScreenPrintingFilter, updateTicketingLabelingFilter } = filterSlice.actions;

export default filterSlice.reducer

export const selectSelectedFilter = state => state.filter.selectedFilter;

export const selectLocationFilter = state => state.filter.where.location;
export const selectPreferredClientFilter = state => state.filter.where.preferredClient;

export const selectShiftFilter = state => state.filter.where.shift;
export const selectWorkStatusFilter = state => state.filter.where.workStatus;
export const selectPositionFilter = state => state.filter.where.position;
export const selectEmploymentTypeFilter = state => state.filter.where.employmentType;
export const selectEthinicityFilter = state => state.filter.where.ethinicity;
export const selectMaritalStatusFilter = state => state.filter.where.maritalStatus;
export const selectGenderFilter = state => state.filter.where.gender;
export const selectCalledOnFilter = state => state.filter.where.calledOn;
export const selectFlexibilityFilter = state => state.filter.where.flexibility;
export const selectAppliedDateFilter = state => state.filter.where.appliedDate;
export const selectLanguageFilter = state => state.filter.where.language;
export const selectAvailableForOTFilter = state => state.filter.where.availableForOT;
export const selectWillingToComuteFilter = state => state.filter.where.willingToComute;
export const selectFastPacedEnvFilter = state => state.filter.where.fastPacedEnv;
export const selectStandingLongPeriodFilter = state => state.filter.where.standingLongPeriod;
export const selectWorkInHotTempFilter = state => state.filter.where.workInHotTemp;
export const selectRepetitiveMovFilter = state => state.filter.where.repetitiveMov;
export const selectWorkinColdTempFilter = state => state.filter.where.workinColdTemp;
export const selectHeavyLiftingFilter = state => state.filter.where.heavyLifting;
export const selectWentOverSafetyProtocolFilter = state => state.filter.where.wentOverSafetyProtocol;
export const selectWentOverUniformProtocolFilter = state => state.filter.where.wentOverUniformProtocol;
export const selectNoShowPolicyFilter = state => state.filter.where.noShowPolicy;
export const selectTimeClockPolicyFilter = state => state.filter.where.timeClockPolicy;
export const selectWeekPayPolicyFilter = state => state.filter.where.weekPayPolicy;
export const selectStillToeBootsFilter = state => state.filter.where.stillToeBoots;
export const selectSlipResistantBootsFilter = state => state.filter.where.slipResistantBoots;
export const selectWorkWeekendsFilter = state => state.filter.where.workWeekends;
export const selectMandateLunchFilter = state => state.filter.where.mandateLunch;
export const selectOneDayAssignmentFilter = state => state.filter.where.oneDayAssignment;
export const selectReadEnglishFilter = state => state.filter.where.ReadEnglish;
export const selectWriteEnglishFilter = state => state.filter.where.WriteEnglish;
export const selectWriteSpanishFilter = state => state.filter.where.WriteSpanish;
export const selectReadSpanishFilter = state => state.filter.where.ReadSpanish;
export const selectBasicMathSkillsFilter = state => state.filter.where.BasicMathSkills;
export const selectCovidVaccinatedFilter = state => state.filter.where.CovidVaccinated;

export const selectGeneralLaberFilter = state => state.filter.where.generalLaber;
export const selectShippingReceivingFilter = state => state.filter.where.shippingReceiving;
export const selectPackagingLabelingFilter = state => state.filter.where.packagingLabeling;
export const selectIndustrialSewingFilter = state => state.filter.where.industrialSewing;
export const selectRfScanningFilter = state => state.filter.where.rfScanning;
export const selectAssemblyLineFilter = state => state.filter.where.assemblyLine;
export const selectFoodProductionFilter = state => state.filter.where.foodProduction;
export const selectMachineOperatorFilter = state => state.filter.where.machineOperator;
export const selectSupervisorLeadFilter = state => state.filter.where.supervisorLead;
export const selectMaintainanceMechanicFilter = state => state.filter.where.maintainanceMechanic;
export const selectElectricianFilter = state => state.filter.where.electrician;
export const selectSanitationFilter = state => state.filter.where.sanitation;
export const selectQualityAssuranceFilter = state => state.filter.where.qualityAssurance;
export const selectForkLiftOperatorFilter = state => state.filter.where.forkLiftOperator;
export const selectSecurityFilter = state => state.filter.where.security;
export const selectComputerFilter = state => state.filter.where.computer;
export const selectApparelMachineOperatorFilter = state => state.filter.where.apparelMachineOperator;
export const selectApparelLoaderFilter = state => state.filter.where.apparelLoader;
export const selectScreenPrintingFilter = state => state.filter.where.screenPrinting;
export const selectTicketingLabelingFilter = state => state.filter.where.ticketingLabeling;

export const selectFilterId = state => state.filter.id;

export const selectFilterName = state => state.filter.name;

export const selectWhereClause = state => state.filter.where;

export const selectDefaultFilter = state => state.filter.defaultFilter;

export const selectRefreshData = state => state.filter.refreshData;

export const selectWhereClauseChanged = state => {
    const whereClause = state.filter.where;
    if (whereClause.location && whereClause.location.value.length > 0) {
        return true;
    }
    if (whereClause.preferredClient && whereClause.preferredClient.value.length > 0) {
        return true;
    }
    
    if (whereClause.shift && whereClause.shift.value.length > 0) {
        return true;
    }
    if (whereClause.workStatus && whereClause.workStatus.value.length > 0) {
        return true;
    }
    if (whereClause.position && whereClause.position.value.length > 0) {
        return true;
    }
    if (whereClause.employmentType && whereClause.employmentType.value.length > 0) {
        return true;
    }
    if (whereClause.ethinicity && whereClause.ethinicity.value.length > 0) {
        return true;
    }
    if (whereClause.maritalStatus && whereClause.maritalStatus.value.length > 0) {
        return true;
    }
    if (whereClause.gender && whereClause.gender.value.length > 0) {
        return true;
    }
    if (whereClause.calledOn) {
        return true;
    }
    if (whereClause.appliedDate.equal != "" || (whereClause.appliedDate.between.start != "" && whereClause.appliedDate.between.end)) {
        return true;
    }
    if (whereClause.flexibility && whereClause.flexibility !== "-1") {
        return true;
    }
    if (whereClause.availableForOT && whereClause.availableForOT !== "-1") {
        return true;
    }
    if (whereClause.willingToComute && whereClause.willingToComute !== "-1") {
        return true;
    }
    if (whereClause.fastPacedEnv && whereClause.fastPacedEnv !== "-1") {
        return true;
    }
    if (whereClause.standingLongPeriod && whereClause.standingLongPeriod !== "-1") {
        return true;
    }
    if (whereClause.workInHotTemp && whereClause.workInHotTemp !== "-1") {
        return true;
    }
    if (whereClause.repetitiveMov && whereClause.repetitiveMov !== "-1") {
        return true;
    }
    if (whereClause.workinColdTemp && whereClause.workinColdTemp !== "-1") {
        return true;
    }
    if (whereClause.heavyLifting && whereClause.heavyLifting !== "-1") {
        return true;
    }
    if (whereClause.wentOverSafetyProtocol && whereClause.wentOverSafetyProtocol !== "-1") {
        return true;
    }
    if (whereClause.wentOverUniformProtocol && whereClause.wentOverUniformProtocol !== "-1") {
        return true;
    }
    if (whereClause.noShowPolicy && whereClause.noShowPolicy !== "-1") {
        return true;
    }
    if (whereClause.timeClockPolicy && whereClause.timeClockPolicy !== "-1") {
        return true;
    }
    if (whereClause.weekPayPolicy && whereClause.weekPayPolicy !== "-1") {
        return true;
    }
    if (whereClause.stillToeBoots && whereClause.stillToeBoots !== "-1") {
        return true;
    }
    if (whereClause.slipResistantBoots && whereClause.slipResistantBoots !== "-1") {
        return true;
    }
    if (whereClause.workWeekends && whereClause.workWeekends !== "-1") {
        return true;
    }
    if (whereClause.mandateLunch && whereClause.mandateLunch !== "-1") {
        return true;
    }
    if (whereClause.oneDayAssignment && whereClause.oneDayAssignment !== "-1") {
        return true;
    }
    if (whereClause.ReadEnglish && whereClause.ReadEnglish !== "-1") {
        return true;
    }
    if (whereClause.ReadSpanish && whereClause.ReadSpanish !== "-1") {
        return true;
    }
    if (whereClause.WriteEnglish && whereClause.WriteEnglish !== "-1") {
        return true;
    }
    if (whereClause.WriteSpanish && whereClause.WriteSpanish !== "-1") {
        return true;
    }
    if (whereClause.CovidVaccinated && whereClause.CovidVaccinated !== "-1") {
        return true;
    }
    if (whereClause.BasicMathSkills && whereClause.BasicMathSkills !== "-1") {
        return true;
    }
    if (whereClause.generalLaber && whereClause.generalLaber.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.shippingReceiving && whereClause.shippingReceiving.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.packagingLabeling && whereClause.packagingLabeling.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.industrialSewing && whereClause.industrialSewing.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.rfScanning && whereClause.rfScanning.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.assemblyLine && whereClause.assemblyLine.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.foodProduction && whereClause.foodProduction.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.machineOperator && whereClause.machineOperator.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.supervisorLead && whereClause.supervisorLead.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.maintainanceMechanic && whereClause.maintainanceMechanic.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.electrician && whereClause.electrician.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.sanitation && whereClause.sanitation.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.qualityAssurance && whereClause.qualityAssurance.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.forkLiftOperator && whereClause.forkLiftOperator.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.security && whereClause.security.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.computer && whereClause.computer.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.apparelMachineOperator && whereClause.apparelMachineOperator.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.apparelLoader && whereClause.apparelLoader.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.screenPrinting && whereClause.screenPrinting.havingExperience !== "-1") {
        return true;
    }
    if (whereClause.ticketingLabeling && whereClause.ticketingLabeling.havingExperience !== "-1") {
        return true;
    }
    return false;
}

export const selectTotalMoreFilterCount = state => {
    const personalFilterCount = selectPersonalFilterCount(state);
    const generalQuesFilterCount = selectGeneralQuestionaireFilterCount(state);
    const wraeHouseExpFilterCount = selectWareHouseExperienceFilterCount(state);
    const otherFilterCount = selectWorkExperienceOtherFilterCount(state);
    return personalFilterCount + generalQuesFilterCount + wraeHouseExpFilterCount + otherFilterCount;
}

export const selectPersonalFilterCount = state => {
    const whereClause = state.filter.where;
    let _count = 0;
    if (whereClause.language && whereClause.language.value.length > 0) {
        _count++;
    }
    if (whereClause.gender && whereClause.gender.value.length > 0) {
        _count++;
    }
    if (whereClause.flexibility && whereClause.flexibility !== "-1") {
        _count++;
    }
    return _count;
}

export const selectGeneralQuestionaireFilterCount = state => {
    const whereClause = state.filter.where;
    let _count = 0;
    if (whereClause.availableForOT && whereClause.availableForOT !== "-1") {
        _count++;
    }
    if (whereClause.willingToComute && whereClause.willingToComute !== "-1") {
        _count++;
    }
    if (whereClause.fastPacedEnv && whereClause.fastPacedEnv !== "-1") {
        _count++;
    }
    if (whereClause.workInHotTemp && whereClause.workInHotTemp !== "-1") {
        _count++;
    }
    if (whereClause.repetitiveMov && whereClause.repetitiveMov !== "-1") {
        _count++;
    }
    if (whereClause.workinColdTemp && whereClause.workinColdTemp !== "-1") {
        _count++;
    }
    if (whereClause.heavyLifting && whereClause.heavyLifting !== "-1") {
        _count++;
    }
    if (whereClause.stillToeBoots && whereClause.stillToeBoots !== "-1") {
        _count++;
    }
    if (whereClause.slipResistantBoots && whereClause.slipResistantBoots !== "-1") {
        _count++;
    }
    if (whereClause.workWeekends && whereClause.workWeekends !== "-1") {
        _count++;
    }
    if (whereClause.oneDayAssignment && whereClause.oneDayAssignment !== "-1") {
        _count++;
    }
    if (whereClause.ReadEnglish && whereClause.ReadEnglish !== "-1") {
        _count++;
    }
    if (whereClause.WriteEnglish && whereClause.WriteEnglish !== "-1") {
        _count++;
    }
    if (whereClause.WriteSpanish && whereClause.WriteSpanish !== "-1") {
        _count++;
    }
    if (whereClause.ReadSpanish && whereClause.ReadSpanish !== "-1") {
        _count++;
    }
    if (whereClause.BasicMathSkills && whereClause.BasicMathSkills !== "-1") {
        _count++;
    }
    if (whereClause.CovidVaccinated && whereClause.CovidVaccinated !== "-1") {
        _count++;
    }
    return _count;
}

export const selectWareHouseExperienceFilterCount = state => {
    const whereClause = state.filter.where;
    let _count = 0;
    if (whereClause.generalLaber && whereClause.generalLaber.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.shippingReceiving && whereClause.shippingReceiving.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.packagingLabeling && whereClause.packagingLabeling.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.rfScanning && whereClause.rfScanning.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.forkLiftOperator && whereClause.forkLiftOperator.havingExperience !== "-1") {
        _count++;
    }
    return _count;
}

export const selectWorkExperienceOtherFilterCount = state => {
    const whereClause = state.filter.where;
    let _count = 0;
    if (whereClause.industrialSewing && whereClause.industrialSewing.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.assemblyLine && whereClause.assemblyLine.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.foodProduction && whereClause.foodProduction.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.machineOperator && whereClause.machineOperator.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.supervisorLead && whereClause.supervisorLead.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.maintainanceMechanic && whereClause.maintainanceMechanic.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.electrician && whereClause.electrician.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.sanitation && whereClause.sanitation.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.qualityAssurance && whereClause.qualityAssurance.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.security && whereClause.security.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.computer && whereClause.computer.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.apparelMachineOperator && whereClause.apparelMachineOperator.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.apparelLoader && whereClause.apparelLoader.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.screenPrinting && whereClause.screenPrinting.havingExperience !== "-1") {
        _count++;
    }
    if (whereClause.ticketingLabeling && whereClause.ticketingLabeling.havingExperience !== "-1") {
        _count++;
    }
    return _count;
}