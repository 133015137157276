import { configureStore } from "@reduxjs/toolkit";

import accountsSlice from "./features/accounts/reducerSlices/accountsSlice";
import layoutReducer from "./component/layouts/reducerSlices/layoutSlice";
import jobRequestModalPopUpReducer from "./component/Controls/modals/reducerSlices/jobRequestModalPopUpSlice";
import pageContainerReducer from "./component/Controls/paginators/paginator/reducerSlices/pageContainerSlice";
import peopleReducer from "./features/people/reducerSlices/peopleSlice";
import filterReducer from "./component/Controls/filters/reducerSlices/filterSilce";
import jobsReducer from "./features/JobOrder/reducerSlices/jobSlice";

export const store = configureStore({
    reducer: {
        accounts: accountsSlice,
        layout: layoutReducer,
        jobRequestModalPopUp: jobRequestModalPopUpReducer,
        pageContainer: pageContainerReducer,
        peoples: peopleReducer,
        filter: filterReducer,
        jobs: jobsReducer
    }
});

export default store;