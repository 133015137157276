import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile, getBase64Image, getFileSizeText, toLocalDate } from '../../../common/common';
import { downloadDocument, selectDocumentDownloadData, selectDocumentDownloadStatus, updateDocumentDownloadStatus } from '../../../features/people/reducerSlices/peopleSlice';
import { fetchStatus } from '../../../api/client';
import { accountService } from '../../../features/accounts/services/accountService';

// import i9Img from './../../../assets/images/clipart/i9.svg';
// import fileImg from './../../../assets/images/clipart/file.svg';
import genderImg from './../../../assets/images/clipart/gender.svg';
import termsImg from './../../../assets/images/clipart/terms.svg';
import folderImg from './../../../assets/images/clipart/folder.svg';
// import filesImg from './../../../assets/images/clipart/files.svg';
import safetyTestAndCertificateImg from './../../../assets/images/clipart/safety-test-and-certificate.svg';
// import covidFormFileImg from './../../../assets/images/clipart/covid-form-file.png';
// import vaccinationDetailsFileImg from './../../../assets/images/clipart/vaccination-details-file.png';

import pdfImg from './../../../assets/images/clipart/pdf.svg';




function DocumentsCard(props) {
    const [documentsTrs, setDocumentsTrs] = useState([]);

    const FORM_DOWNLOAD_FORM = "FormDownloadForm";

    const [fdfSubmitted, setFDFSubmitted] = useState(false);

    const documentDownloadStatus = useSelector(selectDocumentDownloadStatus);
    const documentDownloadData = useSelector(selectDocumentDownloadData);


    const [i9Trs, setI9Trs] = useState([]);
    const [w4Trs, setW4Trs] = useState([]);
    const [ewaTrs, setEwaTrs] = useState([]);
    const [sexualTrs, setSexualTrs] = useState([]);
    const [termsTrs, setTermsTrs] = useState([]);
    const [othersTrs, setOthersTrs] = useState([]);
    const [safetyTestandCertificateTrs, setSafetyTestandCertificateTrs] = useState([]);
    const [covidFormTrs, setCovidFormTrs] = useState([]);
    const [vaccinationDetailsTrs, setVaccinationDetailsTrs] = useState([]);


    const dispatch = useDispatch();
    const isAdmin = accountService.isAdmin();
    const applicant = props.applicant;



    

    const onDownloadClicked = async (e, id, documentName) => {

        await dispatch(downloadDocument({
            client: "test",
            serviceType: "TENANTADMIN",
            operation: "DOWNLOADDOCUMENT",
            payload: {
                id: id,
                data: documentName
            }
        }));
    };

    if (documentDownloadStatus === fetchStatus.SUCCEEDED) {
        if (documentDownloadData) {
            downloadFile(documentDownloadData.documentData, documentDownloadData.documentName);
        }
        dispatch(updateDocumentDownloadStatus());
    }

    let _documentsTrs;
    let _itemDiv;

    useEffect(() => {
        //setapplicant(props.applicant);
        let _applicant = props.applicant;
        let _i9Arr = [];
        let _w4Arr = [];
        let _ewaArr = [];
        let _sexualArr = [];
        let _termsArr = [];
        let _otherArr = [];
        let _safetyTestandCertificateTrsArr = [];
        let _covidFormTrsArr = [];
        let _vaccinationDetailsTrsArr = [];

        if (_applicant && _applicant.Documents && Array.isArray(_applicant.Documents) && _applicant.Documents.length > 0) {

            _documentsTrs = _applicant.Documents.map((doc, index) => (
                _itemDiv = <div className="flyout-list-group-item-card">
                    <div className='sub-icon'>
                        <img src={pdfImg} className="icon-img" />
                        <p className='file-size-text'>{getFileSizeText(doc.Length)}</p>
                    </div>
                    <span className="ps-2">
                        {/* <div className='file-name'>{doc.DocumentName}</div> */}
                        <div className='file-name'>{truncate(doc.DocumentType, 20)}</div>
                        <div className='file-by'>{doc.UploadedBy}, {doc.UploadedOn && toLocalDate(doc.UploadedOn)} </div>
                    </span>
                    <span className="ms-auto d-flex">
                        {/* <img src={downloadImg} alt='not found' className='icon-btn me-2' onClick={(e) => onDownloadClicked(e, _applicant.id, doc.DocumentName)} data-tip='Download' /> */}
                        <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onDownloadClicked(e, _applicant.id, doc.DocumentName)} data-bs-toggle="tooltip" data-bs-placement="top" title="Download"></i>
                    </span>
                </div>,

                doc.DocumentType == 1 ?
                    _i9Arr.push(_itemDiv) : '',

                doc.DocumentType == 2 ?
                    _w4Arr.push(_itemDiv) : '',

                doc.DocumentType == 3 ?
                    _sexualArr.push(_itemDiv) : '',

                doc.DocumentType == 4 ?
                    _termsArr.push(_itemDiv) : '',

                // ['1', '2', '3', '4', '6', 1, 2, 3, 4, 6].includes(doc.DocumentType) ?
                //     '' : _otherArr.push(_itemDiv),

                doc.DocumentType == 5 ?
                    _otherArr.push(_itemDiv) : '',

                doc.DocumentType == 6 ?
                    _ewaArr.push(_itemDiv) : '',

                doc.DocumentType == 7 ?
                    _safetyTestandCertificateTrsArr.push(_itemDiv) : '',

                doc.DocumentType == 8 ?
                    _covidFormTrsArr.push(_itemDiv) : '',

                doc.DocumentType == 10 ?
                    _vaccinationDetailsTrsArr.push(_itemDiv) : '',

                <></>
            ));
            setDocumentsTrs(_documentsTrs);

            setI9Trs(_i9Arr);
            setW4Trs(_w4Arr);
            setEwaTrs(_ewaArr);
            setSexualTrs(_sexualArr);
            setTermsTrs(_termsArr);
            setOthersTrs(_otherArr);
            setSafetyTestandCertificateTrs(_safetyTestandCertificateTrsArr);
            setCovidFormTrs(_covidFormTrsArr);
            setVaccinationDetailsTrs(_vaccinationDetailsTrsArr);
        }
        else {
            setDocumentsTrs(<tr>
                <td colSpan="6">No records available</td>
            </tr>);

            setI9Trs(_i9Arr);
            setW4Trs(_w4Arr);
            setEwaTrs(_ewaArr);
            setSexualTrs(_sexualArr);
            setTermsTrs(_termsArr);
            setOthersTrs(_otherArr);
            setSafetyTestandCertificateTrs(_safetyTestandCertificateTrsArr);
            setCovidFormTrs(_covidFormTrsArr);
            setVaccinationDetailsTrs(_vaccinationDetailsTrsArr);
        }
    }, [props.documents]);


    const truncate = (str, n) => {
        var docText = 'Others';
        if (str == '1') {
            docText = "I9";
        }
        if (str == '2') {
            docText = "W4";
        }
        if (str == '3') {
            docText = "Sexual Harassments Certificate";
        }
        if (str == '4') {
            docText = "Terms";
        }
        if (str == '5') {
            docText = "Others";
        }
        if (str == '6') {
            docText = "EDD";
        }
        if (str == '7') {
            docText = "Safety Test Certificate";
        }
        if (str == '8') {
            docText = "Covid Form";
        }
        if (str == '9') {
            docText = "W4 + EDD";
        }
        if (str == '10') {
            docText = "Vaccination Details";
        }


        return (docText.length > n) ? docText.substr(0, n - 1) + '...' : docText;
    };



    return (
        <>
            <div class="accordion" id="accordionPeopleAttachment">
                {/* <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <img src={i9Img} className="icon-img" />
                        <span className="bold-text ps-2">
                            I9
                        </span>
                        <span className="ms-auto light-text">
                            {i9Trs.length} Items
                        </span>
                    </div>
                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>0kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onFormDownloadClicked(props.applicant.id, "0")} data-bs-toggle="tooltip" data-bs-placement="top" title="Download"></i>
                                </span>
                            </div>
                            {i9Trs}
                        </div>
                    </div>
                </div> */}

                {/* <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <img src={fileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            W4
                        </span>
                        <span className="ms-auto light-text">
                            {w4Trs.length} Items
                        </span>
                    </div>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>0kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onFormDownloadClicked(props.applicant.id, "1")} data-bs-toggle="tooltip" data-bs-placement="top" title="Download"></i>
                                </span>
                            </div>
                            {w4Trs}
                        </div>
                    </div>
                </div> */}

                {/* <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        <img src={eddFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            EDD
                        </span>
                        <span className="ms-auto light-text">
                            {ewaTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>0kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template (English)</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onFormDownloadClicked(props.applicant.id, "2")} data-bs-toggle="tooltip" data-bs-placement="top" title="Download"></i>
                                </span>
                            </div>
                            <div className="flyout-list-group-item-card system">
                                <div className='sub-icon'>
                                    <img src={pdfImg} className="icon-img" />
                                    <p className='file-size-text'>0kb</p>
                                </div>
                                <span className="ps-2">
                                    <div className='file-name'>Pre-Filled Template (Spanish)</div>
                                    <div className='file-by'>System Generated</div>
                                </span>
                                <span className="ms-auto d-flex">
                                    <i className="fa fa-download icon-btn" aria-hidden="true" onClick={(e) => onFormDownloadClicked(props.applicant.id, "3")} data-bs-toggle="tooltip" data-bs-placement="top" title="Download"></i>
                                </span>
                            </div>
                            {ewaTrs}
                        </div>
                    </div>
                </div> */}

                <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <img src={genderImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Sexual Harassment
                        </span>
                        <span className="ms-auto light-text">
                            {sexualTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {sexualTrs}
                        </div>
                    </div>
                </div>

                <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <img src={termsImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Terms
                        </span>
                        <span className="ms-auto light-text">
                            {termsTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {termsTrs}
                        </div>
                    </div>
                </div>

                {/* <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <img src={folderImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Others
                        </span>
                        <span className="ms-auto light-text">
                            {othersTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {othersTrs}
                        </div>
                    </div>
                </div> */}

                <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        <img src={safetyTestAndCertificateImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Safety Test and Certificate
                        </span>
                        <span className="ms-auto light-text">
                            {safetyTestandCertificateTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {safetyTestandCertificateTrs}
                        </div>
                    </div>
                </div>

                {/* <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                        <img src={covidFormFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Covid Form
                        </span>
                        <span className="ms-auto light-text">
                            {covidFormTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {covidFormTrs}
                        </div>
                    </div>
                </div> */}

                {/* <div class="flyout-list-group-item-card-container mt-3">
                    <div className="flyout-list-group-item-card accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                        <img src={vaccinationDetailsFileImg} className="icon-img" />
                        <span className="bold-text ps-2">
                            Vaccination Details
                        </span>
                        <span className="ms-auto light-text">
                            {vaccinationDetailsTrs.length} Items
                        </span>
                    </div>
                    <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionPeopleAttachment">
                        <div className="flyout-list-group-item-card-footer">
                            {vaccinationDetailsTrs}
                        </div>
                    </div>
                </div> */}

               
            </div>
        </>
    );
}

export default DocumentsCard;