import React, { useEffect, useState } from 'react';

import nophoto from './../../assets/images/clipart/nophoto.png';
import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';


import PeopleDetailSubCard from './PeopleDetailSubCard';
import { useSelector } from 'react-redux';
import { selectApplicantById } from '../../features/people/reducerSlices/peopleSlice';
// import { selectLocationOptions } from '../../features/locations/reducerSlices/locationSlice';
// import { _getDateText, _getLocationText } from '../../common/common';
// import ReactTooltip from 'react-tooltip';
import { isObjectArray } from '../../utilities/utilityFunctions';
// import { positionsData } from '../../features/applicant/services/masterData';
import DataNotFoundCard from './DataNotFoundCard';
import { positionsData } from '../../features/people/screens/masterData';
import { _getDateText } from '../../common/common';



function    PeopleDetailCard(props) {
    const applicant = useSelector(selectApplicantById);
    //   const locationOptions = useSelector(selectLocationOptions);

    const [currentScheduleJob, setCurrentScheduleJob] = useState([]);
    const [completedJobs, setCompletedJobs] = useState([]);
    const [sort, setSort] = useState(false);

    const onJobSearch = (e) => {
        let value = e.target.value;
        let searchText = value.toLowerCase();

        if (props.dispatchDetails) {
            let _dispatchDetails = props.dispatchDetails.map((o) => ({ ...o, PositionText: _getPositionText(o.Position) }));

            let _completedJobs = _dispatchDetails.filter((d) => {
                return d.ActiveDate !== '' && (d.Title.toLowerCase().includes(searchText) || d.PayRates.toLowerCase().includes(searchText) || d.PositionText.toLowerCase().includes(searchText))
            });
            let _currentScheduleJob = _dispatchDetails.filter((d) => {
                return d.ActiveDate === '' && (d.Title.toLowerCase().includes(searchText) || d.PayRates.toLowerCase().includes(searchText) || d.PositionText.toLowerCase().includes(searchText))
            });
            if (!sort) {
                _completedJobs = _completedJobs.reverse();
                _currentScheduleJob = _currentScheduleJob.reverse();
            }
            setCompletedJobs(_completedJobs);
            setCurrentScheduleJob(_currentScheduleJob);
        }
    }

    const onSort = (e) => {
        setSort(!sort);
        const _currentScheduleJob = [...currentScheduleJob].reverse();
        const _completedJobs = [...completedJobs].reverse();
        setCurrentScheduleJob(_currentScheduleJob);
        setCompletedJobs(_completedJobs);
    }

    useEffect(() => {
        if (props.dispatchDetails) {
            let _completedJobs = props.dispatchDetails.filter((d) => {
                return d.ActiveDate !== '';
            });
            _completedJobs = _completedJobs.reverse();
            setCompletedJobs(_completedJobs);

            let _currentScheduleJob = props.dispatchDetails.filter((d) => {
                return d.ActiveDate === '';
            });
            _currentScheduleJob = _currentScheduleJob.reverse();
            setCurrentScheduleJob(_currentScheduleJob);
        }
    }, [props.dispatchDetails]);



    const data = [
        { code: 'ST 6451 : Stiiizy', requestDate: '06/30/2022', startDate: 'Jun 27, 2022', startTime: '06:00 AM', jobPosition: 'General Labor', price: '15.50' },
        { code: 'ST 6452 : Stiiizy', requestDate: '06/30/2022', startDate: 'Jun 27, 2022', startTime: '06:00 AM', jobPosition: 'General Labor', price: '15.50' },
        { code: 'ST 6453 : Stiiizy', requestDate: '06/30/2022', startDate: 'Jun 27, 2022', startTime: '06:00 AM', jobPosition: 'General Labor', price: '15.50' },
        { code: 'ST 6454 : Stiiizy', requestDate: '06/30/2022', startDate: 'Jun 27, 2022', startTime: '06:00 AM', jobPosition: 'General Labor', price: '15.50' },
        { code: 'ST 6455 : Stiiizy', requestDate: '06/30/2022', startDate: 'Jun 27, 2022', startTime: '06:00 AM', jobPosition: 'General Labor', price: '21.75' }
    ];

    let statuses = props.statuses;

    let _statusList;
    if (isObjectArray(statuses)) {
        if (statuses.length > 0) {
            switch (props.selectedStatus) {
                case 'Incomplete':
                    _statusList = statuses.map((status, index) => (
                        status.text === "In Progress" ? (
                            <a
                                className="dropdown-item"
                                href="#"
                                onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                                {status.text}
                            </a>
                        ) :
                            (
                                <a
                                    className="dropdown-item-disabled">

                                    {status.text}
                                </a>

                            )

                    ));
                    break;
                case 'In Progress':
                    _statusList = statuses.map((status, index) => (
                        status.text === "Available" || status.text === "InActive" || status.text === "DNU" ? (
                            <a
                                className="dropdown-item"
                                href="#"
                                onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                                {status.text}
                            </a>
                        ) :
                            (
                                <a
                                    className="dropdown-item-disabled">
                                    {status.text}
                                </a>

                            )
                    ));
                    break;
                case 'Available':
                    _statusList = statuses.map((status, index) => (
                        status.text === "DNU" || status.text === "Dispatch" || status.text === "InActive" || status.text === "In Progress" ? (<a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                            {status.text}
                        </a>) :
                            (
                                <a
                                    className="dropdown-item-disabled">

                                    {status.text}
                                </a>

                            )
                    ));
                    break;
                case 'Active':
                    _statusList = statuses.map((status, index) => (
                        status.text === "DNU" || status.text === "InActive" || status.text === "Available" ? (<a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                            {status.text}
                        </a>) :
                            (
                                <a
                                    className="dropdown-item-disabled">

                                    {status.text}
                                </a>

                            )
                    ));
                    break;
                case 'InActive':
                    _statusList = statuses.map((status, index) => (
                        status.text === "DNU" || status.text === "In Progress" || status.text === "Available" ? (<a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                            {status.text}
                        </a>) :
                            (
                                <a
                                    className="dropdown-item-disabled">

                                    {status.text}
                                </a>

                            )
                    ));
                    break;
                case 'DNU':
                    _statusList = statuses.map((status, index) => (
                        status.text === "In Progress" ? (<a
                            className="dropdown-item"
                            href="#"

                            onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                            {status.text}
                        </a>) :
                            (
                                <a
                                    className="dropdown-item-disabled">

                                    {status.text}
                                </a>
                            )
                    ));
                    break;
                case 'Dispatch':
                    _statusList = statuses.map((status, index) => (
                        status.text === "Active" || status.text === "Available" ? (<a
                            className="dropdown-item"
                            href="#"

                            onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                            {status.text}
                        </a>) :
                            (
                                <a
                                    className="dropdown-item-disabled">

                                    {status.text}
                                </a>
                            )
                    ));
                    break;
                default:
                    _statusList = statuses.map((status, index) => (
                        <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => props.onStatusChanged && props.onStatusChanged(e, status.value, status.value)}>
                            {status.text}
                        </a>
                    ));
            }

        }
    }

    const _getPositionText = (id) => {
        if (positionsData) {
            let position = positionsData.find(p => p.value === id);
            if (position && position.text) {
                return position.text;
            }
        }
        return "N/A";
    }


    return (
        <div className="card people-detail-card">
            <div className="people-detail-card-header pb-4">

                <div className="people-detail-card-status-top">
                    Resume <i class="fa-solid fa-download ms-2"></i>
                </div>

                <div className="d-flex justify-content-center pt-3">

                    {/* <button type="button" className="c-btn dark-btn position-relative">
            <i className="fa fa-filter" aria-hidden="true"></i> Filter
            <span className="position-absolute top-100 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
              <span className="visually-hidden">New alerts</span>
            </span>
          </button> */}

                    <div className="people-detail-card-profile position-relative">
                        <div className="people-detail-card-profile-box">
                            {/* <img className="" src={user2Img} /> */}
                            <img className=""
                                src={applicant && applicant.PhotoPath && applicant.PhotoPath.Data ?
                                    applicant.PhotoPath.Data.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "")
                                    : applicant && applicant.Gender && applicant.Gender.Data === 'Female' ? girlFaceImg
                                        : applicant && applicant.Gender && applicant.Gender.Data === 'Male' ? boyFaceImg
                                            : nophoto
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center pt-2">
                    {/* <div className="people-detail-card-name">Allen Yarbrough</div> */}
                    <div className="people-detail-card-name">{applicant ? applicant.FirstName.Data : ""} {applicant ? applicant.LastName.Data : ""}</div>
                </div>
                <div className="d-flex justify-content-center">
                    <p className="">{props.applicantPositions}</p>
                </div>
                <div className="d-flex justify-content-center">
                    <p className="people-detail-card-phone pt-2">
                        <i class="fa-solid fa-briefcase me-2"></i>Completed Jobs 7
                    </p>
                </div>
            </div>

            <div className="btn-group pr-2 pt-4">
                <div className="position-absolute top-0 start-50 translate-middle">
                    {props.isRecordValid &&
                        <>
                            <span className="people-detail-card-select">
                                {props.selectedStatus}
                            </span>
                        </>
                    }

                </div>
            </div>

            <div className="nav people-detail-card-nav pt-3">
                {/* <button className="people-detail-card-nav-btn active" data-bs-toggle="tab" href="#menu1">Jobs</button> */}
                {/* <button className="people-detail-card-nav-btn" data-bs-toggle="tab" href="#menu2">All jobs</button> */}
            </div>

            <div className="people-detail-card-search">
                <i className="fa fa-search"></i>
                <input type="text" className="form-control" placeholder="Search" onChange={(e) => onJobSearch(e)} />
                <button className="" onClick={(e) => onSort(e)}>
                    {/* <img src={sortImg} /> */}
                    <span className={sort ? 'fa fa-arrow-up-wide-short' : 'fa fa-arrow-down-wide-short'}></span>
                </button>
            </div>

            <div className="vh-100 overflow-auto">
                <div class="tab-content mt-0">
                    <div className="tab-pane fade show active" id="menu1">
                        {
                            Object.keys(currentScheduleJob).length === 0 ?
                                <div className='d-flex justify-content-between'>
                                    <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title={'No jobs found '} />
                                </div>
                                :
                                currentScheduleJob && currentScheduleJob.map((d) => {
                                    return <PeopleDetailSubCard
                                        code={d.Title}
                                        requestDate={d.RequestDate && _getDateText(d.RequestDate)}
                                        startDate={d.StartDate && _getDateText(d.StartDate)}
                                        startTime={d.StartTime}
                                        jobPosition={d.Position && _getPositionText(d.Position)}
                                    />
                                })
                        }
                    </div>
                    <div class="tab-pane fade" id="menu2">
                        {
                            Object.keys(completedJobs).length === 0 ?
                                <div className='d-flex justify-content-between'>
                                    <DataNotFoundCard containerClassOverriding={`data-not-found-container-100 mt-5 pt-5`} title={'No jobs found '} />
                                </div>
                                :
                                completedJobs && completedJobs.map((d) => {
                                    return <PeopleDetailSubCard
                                        code={d.Title}
                                        requestDate={d.RequestDate && _getDateText(d.RequestDate)}
                                        startDate={d.StartDate && _getDateText(d.StartDate)}
                                        startTime={d.StartTime}
                                        jobPosition={d.Position && _getPositionText(d.Position)}
                                    />
                                })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PeopleDetailCard