import {
    createSlice,
    createAsyncThunk,
    nanoid
} from '@reduxjs/toolkit';

import { BajoAPI, fetchStatus } from '../../../api/client';
import { getAxiosRequestConfig } from '../../../common/common';
import Collection from '../../../component/Controls/paginators/collection';
import { isJSON } from '../../../utilities/utilityFunctions';

const initialState = {
    data: {
        totalItems: 0,
        items: [],
        status: fetchStatus.IDLE,
        error: null,
        currentPage: 0,
        isFiltered: false,
        records: new Collection(),
        firstRecords: new Collection()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
};

export const getAllJobs = createAsyncThunk('jobs/getAllJobs', async (jobModel, { rejectWithValue }) => {
    const response = await BajoAPI.post('Gateway', jobModel.model, getAxiosRequestConfig());
    const data = response.data ? response.data.data : "[]";
    let jobs;
    if (isJSON(data)) {
        jobs = JSON.parse(data);
    }
    return {
        page: jobModel.page,
        jobs: jobs,
        success: response.data.success
    };
});

export const getJobById = createAsyncThunk('jobs/getJobById', async (jobModel, { rejectWithValue }) => {
    try {
        const response = await BajoAPI.post(`Gateway`, jobModel.model, getAxiosRequestConfig());
        const data = response.data ? response.data.data : undefined;
        let job = undefined;
        if (data && isJSON(data)) {
            job = JSON.parse(data);
        }
        return {
            page: jobModel.page,
            job: job,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export const jobSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        updateStatus: (state) => {
            state.data.status = fetchStatus.DONE;
        },
        updateSingleStatus: (state) => {
            state.single.status = fetchStatus.IDLE;
        },
        loadSingleData: (state, _data) => {
            state.single.data = Object.assign({}, _data.payload);
        },
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload) : _data.payload;
            if (_data.payload) {
                //console.log(_data.payload);
                let _item = state.data.records.Get(state.data.currentPage);
                if (_item && _item.records && Array.isArray(_item.records) && _item.records.length > 0) {
                    let _updatedObjectIndex = _item.records.findIndex(a => a.id === _data.payload.id);
                    let newRecords = _item.records;
                    let _job = {
                        Id: _data.payload.id,
                        Title: _data.payload.Title.Data,
                        Status: _data.payload.Status.Data,
                        Filled: _data.payload.TotalFilled,
                        Total: _data.payload.TotalQuantity,
                        ReportTo: _data.payload.ReportTo.Data,
                    };
                    newRecords[_updatedObjectIndex] = _job;
                    _item.records = newRecords;
                    let records = new Collection();
                    records.Add(_item.key, newRecords);
                    state.data.records.Remove(_item.key);
                    records.Concat(state.data.records);
                    state.data.records = records;
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllJobs.pending, (state, action) => {
            state.data.status = fetchStatus.LOADING;
        }).addCase(getAllJobs.fulfilled, (state, action) => {
            state.data.totalItems = action.payload.jobs.totalItems;
            let _records = new Collection();
            _records.Add(action.payload.page, action.payload.jobs.items);
            if (!state.data.isFiltered) {
                _records.Concat(state.data.records);
            }
            else {
                state.data.firstRecords = new Collection();
                state.data.currentPage = 0;
            }
            state.data.isFiltered = false;
            state.data.records = _records;
            state.data.status = fetchStatus.SUCCEEDED;
            // toaster.success(action.payload.success);
        }).addCase(getAllJobs.rejected, (state, action) => {
            state.data.status = fetchStatus.FAILED;
            state.data.error = action.error.message;
            // toaster.error(action.payload ? action.payload.error : "");
        }).addCase(getJobById.pending, (state, action) => {
            state.single.status = fetchStatus.LOADING;
        }).addCase(getJobById.fulfilled, (state, action) => {
            console.log('job ==> ', action.payload.job)
            state.single.data = action.payload.job;
            if (action.payload.page >= 0) {
                let _records = new Collection();
                _records.Add(action.payload.page, action.payload.job);
                _records.Concat(state.data.firstRecords);
                state.data.firstRecords = _records;
            }
            state.single.status = fetchStatus.SUCCEEDED;
            // toaster.success(action.payload.success);
        }).addCase(getJobById.rejected, (state, action) => {
            state.single.status = fetchStatus.FAILED;
            state.single.error = action.error.message;
            // toaster.error(action.payload ? action.payload.error : "");
        });
    }
});

export const {
    updateStatus,
    updateSingleStatus, updateSingleData, loadSingleData } = jobSlice.actions;


export default jobSlice.reducer


export const selectAllJobs = state => state.jobs.data.records;
export const selectTotalItems = state => state.jobs.data.totalItems;
export const selectStatus = state => state.jobs.data.status;
export const selectCurrentPage = state => state.jobs.data.currentPage;

export const selectJobById = (state) => {
    return state.jobs.single ? state.jobs.single.data : undefined;
}
export const selectSingleStatus = state => state.jobs.single.status;
