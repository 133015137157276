// import React from 'react';
// import { useHistory } from 'react-router-dom';
// import companyLogoImg from '../../assets/images/logo/companyLogo.png';
// import { accountService } from '../../features/accounts/services/accountService';


// function TopNavBar() {
//     const history = useHistory();

//     const user = accountService.getAuthenticatedUser();

//     const onLogoutClicked = (e) => {
//         e.preventDefault();
//         accountService.logout();
//         history.push('/login');
//       };

//     return (
//         <nav class="navbar navbar-expand-lg bg-top-navbar">
//             <div class="container-fluid">
//                 <a class="navbar-brand" href="/">
//                     <img src={companyLogoImg} alt="Logo" width="40" height="40" class="d-inline-block me-2" />
//                     <span className='clientportal-text-logo'>Client Portal</span>
//                 </a>
//                 <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                     <span class="fa-solid fa-bars"></span>
//                 </button>
//                 <div class="collapse navbar-collapse" id="navbarSupportedContent">
//                     <ul class="navbar-nav me-auto mb-2 mb-lg-0">
//                         <li class="nav-item">
//                             <span class="nav-link disabled client-name"> Aaron Thomas</span>
//                         </li>
//                     </ul>
//                     <div className="d-flex">
//                         <ul class="navbar-nav me-auto mb-2 mb-lg-0">
//                             <li class="nav-item">
//                                 <span class="nav-link disabled">Welcome, {user && user.firstName ? user.firstName : ""} {user && user.lastName ? user.lastName : ""}</span>
//                             </li>
//                             <li class="nav-item">
//                                 <button class="btn btn-outline-secondary" type="submit" onClick={(e) => onLogoutClicked(e)}>Log Out</button>
//                             </li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </nav>
//     )
// }

// export default TopNavBar;


import React, { useState, useEffect } from 'react'

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import { accountService } from '../../features/accounts/services/accountService';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectLayoutDetail } from '../layouts/reducerSlices/layoutSlice';


function TopNavBar() {
  const history = useHistory();

  const [title, setTitle] = useState("");

  const user = accountService.getAuthenticatedUser();

  const layoutDetail = useSelector(selectLayoutDetail);

  useEffect(() => {
    if (layoutDetail) {
      setTitle(layoutDetail.title);
    }
  }, [layoutDetail]);


  const onLogoutClicked = (e) => {
    e.preventDefault();
    accountService.logout();
    history.push('/login');
  };

  // if (user) {
  //   console.log('user', user);
  // }

  return (
    <nav className="navbar navbar-expand-md px-3 top-nav-bar">
      <a className="btn border-0 menu-btn">
      <i class="fa-solid fa-bars"></i>
      </a>
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link top-nav-bar-title-text" aria-current="page" href="#">{title}</a>
            </li>
          </ul>
          <form className="d-flex" role="search">
            {/* <input className="form-control input-search me-2" type="search" placeholder="Search" aria-label="Search" /> */}
          </form>
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <div className="top-nav-bar-notification">
                {/* <img src={notificationImg} className="notification" /> */}
              </div>
            </li>
            <li className="nav-item text-center">
              {/* <img src={boyFaceImg} className="menu-profile-photo" /> */}
              <img className="menu-profile-photo"
                src={user && user.photoPath ?
                  user.photoPath.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "")
                  : boyFaceImg     
                }
              />
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle text-primary" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {/* <span>Thomas Foley</span> */}
                <span>{user && user.firstName ? user.firstName : ""} {user && user.lastName ? user.lastName : ""}</span>
              </a>
              <ul className="dropdown-menu dropdown-menu-lg-end">
                {/* <li><a className="dropdown-item" href="#">Action</a></li> */}
                {/* <li><a className="dropdown-item" href="#">Another action</a></li> */}
                {/* <li><hr className="dropdown-divider" /></li> */}
                <li><a className="dropdown-item" href="#" onClick={(e) => onLogoutClicked(e)}>Log Out</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default TopNavBar