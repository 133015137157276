import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeJobRequestModal, selectJobRequestModalPopUpDetail } from './reducerSlices/jobRequestModalPopUpSlice';
import InputGroup from '../custom/inputGroup';
import ShortText from '../textboxes/shortText';
import Selector from '../selectors/selectors';
import DateText from '../textboxes/dateText';
// import TimeInput from '../custom/timeInput';


function JobRequestModalPopUp() {

  const dispatch = useDispatch();
  const modalPopUpDetails = useSelector(selectJobRequestModalPopUpDetail);

  const onCloseClicked = () => {
    dispatch(closeJobRequestModal());
  }


  return (
    <>
      {/* {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
        <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                <button
                  type="button"
                  className="modal-close-button"
                  onClick={onCloseClicked}>
                  <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                </button>
              </div>
              <div className="modal-body">
                Welcome
              </div>
              <div className="modal-footer">
              footer
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show">
        </div>
      </>) : <></>} */}


      {(modalPopUpDetails && modalPopUpDetails.show) ? (<>
        <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">

          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div class="modal-header d-block">
                <div class="d-flex">
                  <h5 className="modal-title">{modalPopUpDetails.title}</h5>
                  <button
                    type="button"
                    className="modal-close-button ms-auto"
                    onClick={onCloseClicked}>
                    <span aria-hidden="true"><i className="fa fa-times" aria-hidden="true"></i></span>
                  </button>
                </div>
                <div class="d-flex">
                  <span class="modal-sub-title pt-2 pe-4">Enter the job details to create a new employment opportunity</span>
                </div>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label id="lblTitle" className="field-label" htmlFor="txtTitle">Title</label>
                    <InputGroup
                      InputGroupClasses="mb-3"
                      icon={<i className="fa fa-pencil-square-o"></i>}
                      control={
                        <ShortText
                          id="txtTitle"
                          name="txtTitle"
                          ShortTextClasses="form-control"
                          placeholder=""
                        />
                      } />
                  </div>

                  <div className="col-md-6">
                    <label id="lblLocations" className="field-label" htmlFor="drpLocations">Location</label>
                    <InputGroup
                      InputGroupClasses="mb-3"
                      icon={<i className="fa fa-map-marker"></i>}
                      control={
                        <Selector
                          id="drpLocations"
                          name="drpLocations"
                          SelectorClasses="form-select"
                          options={
                            <>
                              <option>Buena park</option>
                              <option>Bell</option>
                              <option>Torrance</option>
                            </>
                          }
                        />
                      } />
                  </div>
                </div>

                <div className="row">
                  <div className='col-md-6'>
                    <label id="lblStartDate" className="field-label" htmlFor="txtStartDate">Start Date</label>
                    <InputGroup
                      InputGroupClasses="mb-3"
                      icon={<i className="fa fa-calendar"></i>}
                      control={
                        <DateText
                          id="txtStartDate"
                          name="txtStartDate"
                          DateTextClasses="form-control"
                          placeholder=""
                        />
                      } />
                  </div>

                  {/* <div className='col-md-6'>
                    <label id="lblStartTime" className="field-label" htmlFor="txtStartTime">Start Time</label>
                    <InputGroup
                      InputGroupClasses="mb-3"
                      icon={<i className="fa fa-clock-o"></i>}
                      control={
                        <TimeInput
                          id="txtStartTime"
                          name="txtStartTime"
                          TimeInputClasses="form-control"
                          placeholder=""
                        />
                      } />
                  </div> */}

                  <div className='col-md-6'>
                    <label id="lblEndDate" className="field-label" htmlFor="txtEndDate">End Date</label>
                    <InputGroup
                      InputGroupClasses="mb-3"
                      icon={<i className="fa fa-calendar"></i>}
                      control={
                        <DateText
                          id="txtEndDate"
                          name="txtEndDate"
                          DateTextClasses="form-control"
                          placeholder=""
                        />
                      } />
                  </div>

                  {/* <div className='col-md-6'>
                    <label id="lblEndTime" className="field-label" htmlFor="txtEndTime">End Time</label>
                    <InputGroup
                      InputGroupClasses="mb-3"
                      icon={<i className="fa fa-clock-o"></i>}
                      control={
                        <TimeInput
                          id="txtEndTime"
                          name="txtEndTime"
                          TimeInputClasses="form-control"
                          placeholder=""
                        />
                      } />
                  </div> */}

                  <div className='col-md-12'>
                    <label id="lblDressCode" className="field-label" htmlFor="txtDressCode">Dress Code</label>
                    <InputGroup
                      InputGroupClasses="mb-3"
                      icon={<i className="fa fa-tags"></i>}
                      control={
                        <ShortText
                          id="txtDressCode"
                          name="txtDressCode"
                          ShortTextClasses="form-control"
                          placeholder=""
                        />
                      } />
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={onCloseClicked}>Cancel</button>
                <button type="button" class="btn btn-primary">Save</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show">
        </div>
      </>) : <></>}



    </>
  )
}

export default JobRequestModalPopUp;