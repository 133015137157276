// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import axios from 'axios';

export const BajoAPI = axios.create({

    baseURL: `https://landerr-testapi.azurewebsites.net/api/` //Test Enviroment 
    // baseURL: `https://hirebaseapi.azurewebsites.net/api/` //Hirebase Demo Enviroment 
    // baseURL: `https://localhost:44377/api/` //local Enviroment 
        //baseURL: `/fakeApi/`
        // baseURL: `https://localhost:5001/api/`
});
// export const SignalrUrl = 'https://landerr-testapi.azurewebsites.net/hubs/chat'; //Test Enviroment 
 export const SignalrUrl = 'http://hirebaseapi.azurewebsites.net/hubs/chat'; //Hirebase Demo Enviroment 
// export const SignalrUrl = 'http://localhost:44377/hubs/chat'; //local Enviroment 

export const fetchStatus = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    DONE: 'done'
}