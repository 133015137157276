import React from "react";
import { useHistory } from "react-router-dom";

import boyFaceImg from './../../assets/images/clipart/boy-face.svg';
import girlFaceImg from './../../assets/images/clipart/girl-face.svg';
import nophoto from './../../assets/images/clipart/nophoto.png';


const PeopleCardJob = (props) => {

  const history = useHistory();

  const _getStatusClass = () => {
    if (props.status === "Repeate") {
      return 'status-active';
    }
    else {
      return 'status-incomplete';
    }
  }

  const onClickProfilePic = () => {
    history.push('people/details')
  }

  return (
    <div className="card people-card">
      {/* <div className={`status ${_getStatusClass()}`}>{props.status && props.status}</div> */}
      <div className="people-card-body p-2 cursor-pointer" onClick={props.onClick}>
        <div className="d-flex">
          <div className="pe-1">
            <div className="people-card-photo">
              <div className="people-card-photo-box">
                <img className="people-card-profile-box-img "
                  src={props && props.profilePhoto ?
                    props.profilePhoto.replace("?sv=2020-02-10&ss=bfqt&srt=sco&sp=rwdlacupx&se=2022-05-31T17:08:39Z&st=2021-04-06T09:08:39Z&spr=https&sig=bXyBXZatQS0ncOBkPb7Uw4FjpWY%2FXnOxgHUGZYhexgQ%3D", "")
                    : props && props.gender === 'Female' ? girlFaceImg
                      : props && props.gender === 'Male' ? boyFaceImg
                        : nophoto
                  }
                />
              </div>
            </div>
          </div>
          <div className="ps-2">
            <div className="title w-100">
              {props.fullName}
            </div>
            <div className="row">
              <div className="detail col-4">
                <i class="fa-solid fa-briefcase me-1"></i>Completed Jobs: {props.noOfJobs}
              </div>
              <div className="detail col-8">
                <i class="fa-solid fa-id-card-clip me-1"></i>Position: {props.position}
              </div>
              <div className="detail col-4">
                <i class="fa-solid fa-calendar me-1"></i>DOB: {props.dateOfBirth}
              </div>
              <div className="detail col-8">
                {/* <i class="fa-solid fa-briefcase"></i> {props.location} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="people-card-footer">
        <div className="item">
          <span className="detail">
            <i class="fa-solid fa-clock me-1"></i>
            DT: {props.workingSince}
          </span>
        </div>
        <div className="item">
          <span className="detail cursor-pointer" >
            <i class="fa-solid fa-medal me-1"></i>
            Experience: {props.Experience}
          </span>
        </div>
      </div>
    </div>
  )
}

export default PeopleCardJob;
