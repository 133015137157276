import React from 'react'

function JobTimelineCard(props) {
    return (
        <div className='timeline-card mb-3'>
            <div className='row p-3'>
                <div className='col-md-5'>
                    <p className='title'>{props.position} ( {props.jobType})</p>
                    <span className='sub-title'>Start Date - {props.startDate} {props.startTime}</span>
                </div>
                <div className='col-md-5'>
                    <p className='title'> {props.title}</p>
                    <span className='sub-title'>{props.address}</span>
                </div>
                <div className='col-md-2'>
                    {/* Check In */}
                </div>
            </div>
            <div className='px-3 py-1'>
                <span class="badge rounded-pill badge-blue me-3"><i className='fa fa-user me-2' />Report To: {props.reportTo}</span>
                <span class="badge rounded-pill badge-yellow me-3"><i className='fa fa-location-dot me-2' />Arrived: 11/25/2022, 22:48:33</span>
                <span class="badge rounded-pill badge-purple me-3"><i className='fa fa-shirt me-2' />Dress Code: {props.dressCode}</span>
                <span class="badge rounded-pill badge-indigo-blue"><i className='fa fa-sack-dollar me-2' />${props.payRates}</span>
            </div>

            <div className='h-line mt-3' />
            <div className='row p-3'>
                <div className='col-md-12'>
                    <span className='note'>
                        Note : Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default JobTimelineCard;